html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

img, fieldset, a img {
  border: none;
}

input[disabled] {
  cursor: not-allowed;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-activity {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}

.icon-arrow-down-square {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-arrow-down {
  width: 1.5em;
  height: 1em;
  fill: #11142D;
}

.icon-arrow-right {
  width: 1.45em;
  height: 1em;
  fill: #1b1d21;
}

.icon-arrow-up-right-square {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-arrow-up-right {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-award {
  width: 0.63em;
  height: 1em;
  fill: #1B1D21;
}

.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-chart {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-check {
  width: 1.14em;
  height: 1em;
  fill: #11142D;
}

.icon-clock {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: #11142D;
}

.icon-copy {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-deposit {
  width: 1.06em;
  height: 1em;
  fill: #fff;
}

.icon-discount {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-document-plus {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}

.icon-document-two-lines {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}

.icon-document {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}

.icon-dots {
  width: 1em;
  height: 1em;
  fill: #1B1D21;
}

.icon-home {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-info-circle {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-info-square {
  width: 1.07em;
  height: 1em;
  fill: #11142D;
}

.icon-link {
  width: 1.14em;
  height: 1em;
  fill: #11142D;
}

.icon-notification {
  width: 0.91em;
  height: 1em;
  fill: #808191;
}

.icon-ranking {
  width: 1.14em;
  height: 1em;
  fill: initial;
}

.icon-search {
  width: 1em;
  height: 1em;
  fill: #11142D;
}

.icon-settings {
  width: 0.91em;
  height: 1em;
  fill: #808191;
}

.icon-share {
  width: 1.06em;
  height: 1em;
  fill: #11142D;
}

.icon-star-fill {
  width: 1.06em;
  height: 1em;
  fill: #11142D;
}

.icon-star {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-swap {
  width: 1.08em;
  height: 1em;
  fill: #808191;
}

.icon-theme-dark {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-theme-light {
  width: 1.04em;
  height: 1em;
  fill: #808191;
}

.icon-time-clock {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-verify {
  width: 1.06em;
  height: 1em;
  fill: #1B1D21;
}

.icon-wallet {
  width: 1.1em;
  height: 1em;
  fill: #808191;
}

.icon-withdraw {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  -o-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11142D;
}

body.dark {
  background: #1F2128;
  color: #ffffff;
}

body.no-scroll,
html.no-scroll {
  overflow: hidden;
}

button,
input,
textarea,
select {
  font-family: 'Inter', sans-serif;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.select {
  height: 64px;
  padding-left: 20px;
  padding-right: 45px;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 16px;
  border: none;
  float: none;
  line-height: 64px;
  font-weight: 600;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.select:after {
  display: none;
}

.select:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 12px;
  height: 7px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%2311142d'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.select.open:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select:not(.select_empty).open {
  -webkit-box-shadow: inset 0 0 0 2px #355DFF;
  box-shadow: inset 0 0 0 2px #355DFF;
  background: #ffffff;
}

.select .list {
  right: 0;
  margin-top: 7px;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #E4E4E4;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
}

.select .option {
  position: relative;
  padding-left: 28px;
  min-height: auto;
  line-height: 1.71429;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.select .option:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 13px;
  background: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23355DFF'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
  opacity: 0;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.select .option:hover, .select .option.focus, .select .option.selected.focus {
  background: none;
}

.select .option:hover {
  color: #11142D;
}

.select .option.selected {
  color: #355DFF;
}

.select .option.selected:before {
  opacity: 1;
}

.select .option:not(:last-child) {
  margin-bottom: 8px;
}

.select_sm {
  height: 56px;
  line-height: 56px;
  border-radius: 8px;
}

.select_empty {
  padding-left: 0;
  padding-right: 32px;
  height: 24px;
  background: none;
  border-radius: 0;
  line-height: 24px;
}

.select_empty:before {
  right: 8px;
}

.select_empty .list {
  left: auto;
  right: -16px;
  width: 192px;
  margin-top: 19px;
}

.select_arrows:before {
  width: 12px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18'%3E%3Cpath fill='%231b1d21' d='M11.247 11.836a1 1 0 0 1 .006 1.323l-.089.089-4.5 4a1 1 0 0 1-1.23.077l-.099-.077-4.5-4a1 1 0 0 1 1.23-1.572l.099.078L6 15.161l3.836-3.408a1 1 0 0 1 1.412.083zM6.566.675l.099.077 4.5 4a1 1 0 0 1-1.23 1.572l-.099-.078L6 2.838 2.164 6.247a1 1 0 0 1-1.323.006l-.089-.089a1 1 0 0 1-.006-1.323l.089-.089 4.5-4a1 1 0 0 1 1.23-.077z'/%3E%3C/svg%3E");
}

.select_arrows .list {
  margin-top: 2px;
  padding: 12px 19px;
  border-radius: 8px;
}

.select_arrows .option {
  padding-left: 0;
}

.select_arrows .option:before {
  display: none;
}

select.select {
  opacity: 0;
}

body.dark .select:not(.select_empty) {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .select:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%23ffffff'/%3E%3C/svg%3E");
}

body.dark .select .list {
  border-color: rgba(228, 228, 228, 0.1);
  background: #191B20;
}

body.dark .select .option:hover {
  color: #ffffff;
}

body.dark .select .option.selected {
  color: #355DFF;
}

body.dark .select_arrows:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18'%3E%3Cpath fill='%23ffffff' d='M11.247 11.836a1 1 0 0 1 .006 1.323l-.089.089-4.5 4a1 1 0 0 1-1.23.077l-.099-.077-4.5-4a1 1 0 0 1 1.23-1.572l.099.078L6 15.161l3.836-3.408a1 1 0 0 1 1.412.083zM6.566.675l.099.077 4.5 4a1 1 0 0 1-1.23 1.572l-.099-.078L6 2.838 2.164 6.247a1 1 0 0 1-1.323.006l-.089-.089a1 1 0 0 1-.006-1.323l.089-.089 4.5-4a1 1 0 0 1 1.23-.077z'/%3E%3C/svg%3E");
}

body.dark .select.open {
  background: none;
}

.status {
  position: relative;
  line-height: 16px;
}

.status:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
}

.status.positive {
  color: #4FBF67;
}

.status.positive:before {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}

.status.negative {
  color: #FF7A68;
}

.more {
  margin-top: 32px;
  text-align: center;
}

.more__btn {
  position: relative;
}

.more__btn .loader {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 3px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load 1.1s infinite ease;
  animation: load 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load {
  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #4F54F6, 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    box-shadow: 0em -2.6em 0em 0em #4F54F6, 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #4F54F6, 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #4F54F6, 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #4F54F6, 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #4F54F6, 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #4F54F6, 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #4F54F6, 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #4F54F6, -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #4F54F6, -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #4F54F6, -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #4F54F6, -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #4F54F6, -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #4F54F6, -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #4F54F6;
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #4F54F6;
  }
}

@keyframes load {
  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #4F54F6, 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.5), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.7);
    box-shadow: 0em -2.6em 0em 0em #4F54F6, 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.5), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.7);
  }
  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.7), 1.8em -1.8em 0 0em #4F54F6, 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.7), 1.8em -1.8em 0 0em #4F54F6, 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.5);
  }
  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.5), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.7), 2.5em 0em 0 0em #4F54F6, 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.5), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.7), 2.5em 0em 0 0em #4F54F6, 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.5), 2.5em 0em 0 0em rgba(79, 84, 246, 0.7), 1.75em 1.75em 0 0em #4F54F6, 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.5), 2.5em 0em 0 0em rgba(79, 84, 246, 0.7), 1.75em 1.75em 0 0em #4F54F6, 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.5), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.7), 0em 2.5em 0 0em #4F54F6, -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.5), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.7), 0em 2.5em 0 0em #4F54F6, -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.2), -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.5), 0em 2.5em 0 0em rgba(79, 84, 246, 0.7), -1.8em 1.8em 0 0em #4F54F6, -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.5), 0em 2.5em 0 0em rgba(79, 84, 246, 0.7), -1.8em 1.8em 0 0em #4F54F6, -2.6em 0em 0 0em rgba(79, 84, 246, 0.2), -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.5), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.7), -2.6em 0em 0 0em #4F54F6, -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.5), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.7), -2.6em 0em 0 0em #4F54F6, -1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2);
  }
  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.5), -2.6em 0em 0 0em rgba(79, 84, 246, 0.7), -1.8em -1.8em 0 0em #4F54F6;
    box-shadow: 0em -2.6em 0em 0em rgba(79, 84, 246, 0.2), 1.8em -1.8em 0 0em rgba(79, 84, 246, 0.2), 2.5em 0em 0 0em rgba(79, 84, 246, 0.2), 1.75em 1.75em 0 0em rgba(79, 84, 246, 0.2), 0em 2.5em 0 0em rgba(79, 84, 246, 0.2), -1.8em 1.8em 0 0em rgba(79, 84, 246, 0.5), -2.6em 0em 0 0em rgba(79, 84, 246, 0.7), -1.8em -1.8em 0 0em #4F54F6;
  }
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px 0 0 -8px;
}

.pagination__item, .pagination__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 48px;
  margin: 8px 0 0 8px;
  border-radius: 12px;
  border: 1px solid #E4E4E4;
  font-size: 14px;
  font-weight: 700;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.pagination__item:hover, .pagination__next:hover {
  border-color: #355DFF;
}

.pagination__item {
  width: 48px;
}

.pagination__next {
  width: 105px;
}

.pagination__next .icon {
  margin-left: 15px;
}

.pagination__item.active {
  background: #355DFF;
  border-color: #355DFF;
  color: #ffffff;
}

body.dark .pagination__item, body.dark .pagination__next {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .pagination__item .icon, body.dark .pagination__next .icon {
  fill: #ffffff;
}

body.dark .pagination__item:hover, body.dark .pagination__next:hover {
  border-color: #355DFF;
}

.favorite {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.favorite:before, .favorite:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.favorite:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M8 .867l2.318 4.696 5.182.753-3.75 3.655.885 5.162L8 12.696l-4.635 2.437.885-5.162L.5 6.316l5.182-.753L8 .867z' stroke='%23808191' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.favorite:after {
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M16.144 5.439l-4.317-.628L9.9.9a1.04 1.04 0 0 0-1.8 0L6.173 4.812l-4.317.627A1 1 0 0 0 1.3 7.145l3.123 3.045-.737 4.3a1 1 0 0 0 1.451 1.054L9 13.513l3.861 2.029a1 1 0 0 0 1.451-1.054l-.737-4.3L16.7 7.145a1 1 0 0 0-.554-1.705l-.002-.001z' fill='%23355dff'/%3E%3C/svg%3E");
  opacity: 0;
}

.favorite.active:before {
  opacity: 0;
}

.favorite.active:after {
  opacity: 1;
}

body.dark .apexcharts-tooltip.apexcharts-theme-light {
  color: #11142D;
}

body.dark .apexcharts-gridlines-horizontal line {
  stroke: rgba(228, 228, 228, 0.1);
}

.page {
  overflow: hidden;
}

.page__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  -webkit-transition: padding .2s;
  -o-transition: padding .2s;
  transition: padding .2s;
}

.page__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.page__col {
  padding: 0 64px 64px;
}

.page__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 426px);
  flex: 0 0 calc(100% - 426px);
  max-width: calc(100% - 426px);
}

.page__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 426px;
  flex: 0 0 426px;
  width: 426px;
}

.page__col_pt100 {
  padding-top: 100px;
}

/*.page__col_pt198 {
  padding-top: 198px;
}*/

.page__col_pt198 {
  padding-top: 118px;
}

.page__head {
  margin-top: -80px;
  margin-bottom: 32px;
}

.page__col > .page__title {
  margin-bottom: 32px;
}

.page.wide .page__content,
.page.full .page__content {
  padding-left: 96px;
}

.page.full.wide .page__content {
  padding-left: 96px;
}

body.dark .page__col:nth-child(2) {
  border-color: rgba(228, 228, 228, 0.1);
}

.btn {
  min-width: 114px;
  height: 48px;
  padding: 0 24px;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.btn img, .btn__text,
.btn .icon {
  display: inline-block;
  vertical-align: middle;
}

.btn img,
.btn .icon {
  margin-right: 8px;
}

.btn .icon {
  font-size: 16px;
}

.btn_white {
  background: #ffffff;
  color: #11142D;
}

.btn_white:hover {
  background: #E4E4E4;
}

.btn_blue {
  background: #355DFF;
  color: #ffffff;
}

.btn_blue:hover {
  background: #1140ff;
}

.btn_border {
  border: 1px solid #E4E4E4;
}

.btn_border:hover {
  border-color: #11142D;
}

.btn_gray {
  background: #E4E4E4;
  color: #808191;
}

.btn_gray:hover {
  background: #808191;
  color: #ffffff;
}

.btn_transparent {
  background: transparent;
}

.btn_transparent:hover {
  color: #355DFF;
}

.btn_sm {
  height: 40px;
}

.btn_big {
  height: 64px;
}

.btn_wide {
  min-width: 100%;
}

.btn[href] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

body.dark .btn_white {
  background: #242731;
  color: #ffffff;
}

body.dark .btn_white:hover {
  background: #1B1D21;
}

body.dark .btn_border {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .btn_border .icon {
  fill: #ffffff;
}

body.dark .btn_border:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.1);
}

body.dark .btn_gray {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .btn_transparent {
  color: #ffffff;
}

body.dark .btn_transparent:hover {
  color: #355DFF;
}

.switch {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  -webkit-transition: opacity .25s;
  -o-transition: opacity .25s;
  transition: opacity .25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__in {
  display: inline-block;
}

.switch__box {
  position: relative;
  display: block;
  width: 72px;
  height: 32px;
  background: rgba(228, 228, 228, 0.4);
  border-radius: 16px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.switch__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #3F8CFF;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.switch__icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}

.switch__icon .icon {
  font-size: 24px;
  fill: #808191;
}

.switch__icon .icon-theme-dark {
  display: none;
}

.switch__input:checked + .switch__flex .switch__box:before {
  -webkit-transform: translate(24px, -50%);
  -ms-transform: translate(24px, -50%);
  transform: translate(24px, -50%);
}

.switch_theme .switch__in {
  position: relative;
  padding-left: 46px;
  -webkit-transition: padding .2s;
  -o-transition: padding .2s;
  transition: padding .2s;
}

.switch_theme .switch__input:checked + .switch__in .switch__box:before {
  width: 8px;
  height: 24px;
  border-radius: 4px;
  -webkit-transform: translate(48px, -50%);
  -ms-transform: translate(48px, -50%);
  transform: translate(48px, -50%);
}

.switch_theme .switch__input:checked + .switch__in .icon-theme-dark {
  display: inline-block;
}

.switch_theme .switch__input:checked + .switch__in .icon-theme-light {
  display: none;
}

body.dark .switch__box {
  background: rgba(228, 228, 228, 0.1);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 255px;
  background: #ffffff;
  border-right: 1px solid #E4E4E4;
  padding: 132px 20px 72px;
  -webkit-transition: width .2s;
  -o-transition: width .2s;
  transition: width .2s;
}

.sidebar__head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 132px;
  padding-top: 12px;
}

.sidebar__head .sidebar__close {
  display: none;
  margin-left: 8px;
  font-size: 0;
}

.sidebar__head .sidebar__close .icon {
  font-size: 18px;
  fill: #11142D;
}

.sidebar__logo {
  display: inline-block;
  margin-right: 28px;
  font-size: 0;
}

.sidebar__pic_dark {
  display: none;
}

.sidebar__toggle {
  font-size: 0;
}

.sidebar__toggle svg {
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.sidebar__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar__body::-webkit-scrollbar {
  display: none;
}

.sidebar__nav {
  margin-bottom: 32px;
}

.sidebar__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-radius: 12px;
  white-space: nowrap;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.sidebar__item:hover, .sidebar__item.active {
  color: #355DFF;
}

.sidebar__item:hover .icon, .sidebar__item.active .icon {
  fill: #355DFF;
}

.sidebar__item.active {
  background: rgba(228, 228, 228, 0.3);
}

.sidebar__icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.sidebar__icon .icon {
  font-size: 22px;
  fill: #808191;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.sidebar__icon .icon-wallet, .sidebar__icon .icon-document, .sidebar__icon .icon-discount {
  font-size: 20px;
}

.sidebar__icon:before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background: #FF6628;
  border-radius: 50%;
  opacity: 0;
}

.sidebar__icon.active:before {
  opacity: 1;
}

.sidebar__item .sidebar__text {
  margin-left: 16px;
}

.sidebar__mail {
  position: relative;
  margin-top: auto;
  padding: 24px;
  border-radius: 24px;
  background: -webkit-linear-gradient(226.42deg, #86E1A0 12.52%, #4FBF67 75.73%);
  background: -o-linear-gradient(226.42deg, #86E1A0 12.52%, #4FBF67 75.73%);
  background: linear-gradient(223.58deg, #86E1A0 12.52%, #4FBF67 75.73%);
}

.sidebar__info {
  max-width: 128px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 500;
  color: #ffffff;
}

.sidebar__mail .sidebar__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.sidebar__mail .sidebar__close .icon {
  font-size: 9px;
  fill: #11142D;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.sidebar__mail .sidebar__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar__btn {
  margin-top: 12px;
}

.sidebar__search {
  display: none;
  position: relative;
  margin-top: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.sidebar__input {
  width: 100%;
  height: 56px;
  padding: 0 56px 0 24px;
  background: rgba(228, 228, 228, 0.3);
  border: none;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142D;
}

.sidebar__input::-webkit-input-placeholder {
  color: #808191;
}

.sidebar__input::-ms-input-placeholder {
  color: #808191;
}

.sidebar__input::placeholder {
  color: #808191;
}

.sidebar__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  font-size: 0;
}

.sidebar__start .icon {
  font-size: 20px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.sidebar__start:hover .icon {
  fill: #355DFF;
}

.sidebar__bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  padding: 0 20px;
  -webkit-transition: padding .2s;
  -o-transition: padding .2s;
  transition: padding .2s;
}

.sidebar__user {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar__user img {
  width: 36px;
}

.sidebar.active, .sidebar.small {
  width: 96px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.sidebar.small.active {
  width: 255px;
  -webkit-box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
}

.sidebar.active .sidebar__logo,
.sidebar.small .sidebar__logo {
  display: none;
}

.sidebar.small.active .sidebar__logo {
  display: inline-block;
}

.active .sidebar__toggle svg,
.sidebar.small .sidebar__toggle svg {
  -webkit-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.sidebar.small.active .sidebar__toggle svg {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.sidebar.active .sidebar__item,
.sidebar.small .sidebar__item {
  padding: 0 16px;
}

.sidebar.small.active .sidebar__item {
  padding: 0 20px;
}

.sidebar.active .sidebar__mail,
.sidebar.small .sidebar__mail {
  display: none;
}

.sidebar.small.active .sidebar__mail {
  display: block;
}

.sidebar.active .sidebar__bottom,
.sidebar.small .sidebar__bottom {
  padding: 0 12px;
}

.sidebar.small.active .sidebar__bottom {
  padding: 0 20px;
}

.sidebar.active .switch__in,
.sidebar.small .switch__in {
  padding: 0;
}

.sidebar.small.active .switch__in {
  padding-left: 46px;
}

.sidebar.active .switch__icon, .sidebar.small .switch__icon {
  left: 46px;
}

.sidebar.active .switch__icon .icon, .sidebar.small .switch__icon .icon {
  font-size: 16px;
}

.sidebar.active .switch__input:checked + .switch__in .switch__icon, .sidebar.small .switch__input:checked + .switch__in .switch__icon {
  left: 10px;
}

.sidebar.small.active .switch__icon {
  left: 0;
}

.sidebar.small.active .switch__icon .icon {
  font-size: 24px;
}

.sidebar.small.active .switch__input:checked + .switch__in .switch__icon {
  left: 0;
}

body.dark .sidebar {
  background: #1F2128;
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .sidebar__pic_light {
  display: none;
}

body.dark .sidebar__pic_dark {
  display: inline-block;
}

body.dark .sidebar__toggle svg > path {
  stroke: #ffffff;
}

body.dark .sidebar__toggle svg g {
  stroke: rgba(128, 129, 145, 0.7);
}

body.dark .sidebar__toggle svg g path:nth-child(2) {
  opacity: .6;
}

body.dark .sidebar__item:hover {
  color: #ffffff;
}

body.dark .sidebar__item:hover .icon {
  fill: #ffffff;
}

body.dark .sidebar__item.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .sidebar__item.active .icon {
  fill: #355DFF;
}

body.dark .sidebar__mail .sidebar__close {
  background: #242731;
}

body.dark .sidebar__mail .sidebar__close .icon {
  fill: #ffffff;
}

body.dark .sidebar__mail .sidebar__btn {
  background: #ffffff;
  color: #11142D;
}

body.dark .sidebar__mail .sidebar__btn:hover {
  background: #E4E4E4;
}

body.dark .sidebar__head .sidebar__close .icon {
  fill: #ffffff;
}

body.dark .sidebar__input {
  background: rgba(25, 27, 32, 0.3);
  color: #ffffff;
}

body.dark .sidebar__start .icon {
  fill: #ffffff;
}

.header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 48px 64px 36px;
}

.header__search {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 210px;
  margin-right: auto;
}

.header__input {
  width: 100%;
  height: 48px;
  padding-right: 32px;
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142D;
}

.header__input::-webkit-input-placeholder {
  color: #808191;
}

.header__input::-ms-input-placeholder {
  color: #808191;
}

.header__input::placeholder {
  color: #808191;
}

.header__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  font-size: 0;
}

.header__start .icon {
  font-size: 20px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.header__start:hover .icon {
  fill: #355DFF;
}

.header__logo {
  display: none;
  margin-right: auto;
}

.header__logo img {
  width: 60px;
}

.header__logo.hidden {
  visibility: hidden;
  opacity: 0;
}

.header__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.header__indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
  padding: 0 12px 0 6px;
  background: #355DFF;
  border-radius: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
}

.header__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 4px 7px rgba(17, 20, 45, 0.402414);
  box-shadow: 0px 4px 7px rgba(17, 20, 45, 0.402414);
  background: #ffffff;
}

.header__item {
  position: relative;
}

.header__item_notifications {
  margin-right: 0;
  font-size: 0;
}

.header__item_download {
  margin-right: 22px;
  font-size: 0;
}

.header__item_notifications .header__head,
.header__item_download .header__head {
  width: 24px;
  height: 24px;
  font-size: 0;
}

.header__item_notifications .header__head .icon,
.header__item_download .header__head .icon {
  font-size: 20px;
  fill: #808191;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.header__item_notifications .header__head:hover .icon,
.header__item_download .header__head:hover .icon {
  fill: #355DFF;
}

.header__item_lang .header__head {
  position: relative;
  padding-right: 42px;
  font-weight: 600;
}

.header__item_lang .header__head:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 12px;
  height: 7px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%2311142d'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.header__item_lang.active .header__head:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.header__item_notifications .header__head {
  position: relative;
}

.header__item_notifications .header__head:before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background: #FF6628;
  border-radius: 50%;
  opacity: 0;
}

.header__item_notifications .header__head.active:before {
  opacity: 1;
}

.header__body {
  position: absolute;
  background: #ffffff;
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.header__item.active .header__body {
  visibility: visible;
  opacity: 1;
}

.header__item_notifications .header__body {
  top: calc(100% + 15px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 375px;
  padding: 0 24px 32px;
  -webkit-box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
  box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
}

.header__item_download .header__body {
  top: calc(100% + 22px);
  left: 50%;
  width: 338px;
  padding: 32px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #E4E4E4;
  -webkit-box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
  box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
}

.header__item_lang .header__body {
  top: calc(100% + 22px);
  right: -15px;
  width: 300px;
  border: 1px solid #E4E4E4;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
  box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
}

.header__notifications {
  margin-bottom: 24px;
}

.header__notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
  color: inherit;
}

.header__notification:not(:last-child) {
  border-bottom: 1px solid #E4E4E4;
}

.header__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 50%;
  background: linear-gradient(rgb(53, 93, 255) 0%, rgb(59, 85, 246) 57.81%, rgb(63, 80, 239) 100%);
}

.header__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header__info {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.header__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__time {
  font-size: 14px;
  font-weight: 600;
  color: #808191;
}

.header__status {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  background: #3F8CFF;
  border-radius: 50%;
}

.header__btn {
  font-weight: 700;
}

.header__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header__col:first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 40px;
}

.header__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 94px;
}

.header__category {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
}

.header__download {
  display: block;
}

.header__download:not(:last-child) {
  margin-bottom: 12px;
}

.header__lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header__cell {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  padding: 24px 22px;
}

.header__cell:first-child {
  border-right: 1px solid #E4E4E4;
}

.header__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.header__link:hover, .header__link.active {
  color: #355DFF;
}

.header__link:not(:last-child) {
  margin-bottom: 12px;
}

.header__flag {
  margin-right: 16px;
}

.header__user {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  margin-left: 36px;
}

.header__user img {
  min-width: 100%;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.header__toggle {
  display: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 32px;
}

.header_border:before {
  content: "";
  position: absolute;
  top: 0;
  right: 425px;
  bottom: 0;
  width: 1px;
  background: #E4E4E4;
}

body.dark .header__input {
  color: #ffffff;
}

body.dark .header__start .icon {
  fill: #ffffff;
}

body.dark .header__start:hover .icon {
  fill: #355DFF;
}

body.dark .header__body {
  background: #242731;
}

body.dark .header__item_notifications .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .header__notification {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .header__item_lang .header__head {
  color: #ffffff;
}

body.dark .header__item_lang .header__head:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%23ffffff'/%3E%3C/svg%3E");
}

body.dark .header__item_lang .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .header__cell:first-child {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .header__link:hover {
  color: #ffffff;
}

body.dark .header__link.active {
  color: #355DFF;
}

body.dark .header__item_download .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .header__toggle svg > path {
  stroke: #ffffff;
}

body.dark .header__toggle svg g {
  stroke: rgba(128, 129, 145, 0.7);
}

body.dark .header__toggle svg g path:nth-child(2) {
  opacity: .6;
}

body.dark .header_border:before {
  background: rgba(228, 228, 228, 0.1);
}

.slider {
  position: relative;
}

.slider__close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.slider__close .icon {
  font-size: 9px;
  fill: #11142D;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.slider__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.slider__container {
  margin: 0 -16px;
}

.slider__item {
  position: relative;
  margin: 0 16px;
  padding: 32px 32px 64px;
  border-radius: 24px;
  overflow: hidden;
}

.slider__wrap {
  position: relative;
  z-index: 2;
  color: #ffffff;
}

.slider__date {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: .9px;
  text-transform: uppercase;
}

.slider__title {
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.5px;
  font-weight: 500;
}

.slider__info {
  margin-bottom: 24px;
}

.slider__btn {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.152444);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.152444);
}

.slider__preview {
  position: absolute;
}

.slider__preview img {
  width: 100%;
}

.slider .owl-dots {
  position: absolute;
  left: 48px;
  bottom: 32px;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider .owl-carousel button.owl-dot {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.slider .owl-carousel button.owl-dot.active {
  background: #ffffff;
}

.slider .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 4px;
}

.slider_home .slider__item {
  min-height: 393px;
  background: -webkit-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: -o-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: radial-gradient(103.03% 103.03% at 0% 0%, #D080FF 0%, #6C5DD3 100%);
}

.slider_home .slider__wrap {
  max-width: 260px;
}

.slider_home .slider__preview {
  left: 30%;
  bottom: -157px;
  width: 740px;
}

.slider_promotions .slider__item {
  min-height: 508px;
  background: -webkit-radial-gradient(100% 2.73%, 51.4% 51.4%, #D080FF 0%, #6C5DD3 100%, #6C5DD3 100%);
  background: -o-radial-gradient(100% 2.73%, 51.4% 51.4%, #D080FF 0%, #6C5DD3 100%, #6C5DD3 100%);
  background: radial-gradient(51.4% 51.4% at 100% 2.73%, #D080FF 0%, #6C5DD3 100%, #6C5DD3 100%);
}

.slider_promotions .slider__wrap {
  max-width: 310px;
}

.slider_promotions .slider__preview {
  left: 30%;
  bottom: -16px;
  width: 790px;
}

.slider:not(:last-child) {
  margin-bottom: 64px;
}

body.dark .slider__close {
  background: #242731;
}

body.dark .slider__close .icon {
  fill: #ffffff;
}

.widgets__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.widgets__sorting .select {
  margin-top: 8px;
  margin-left: 30px;
}

.widgets__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px auto 0 -8px;
}

.widgets__variants .widgets__link {
  min-width: 100px;
  margin: 8px 0 0 8px;
  padding: 0 8px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  line-height: 40px;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.widgets__variants .widgets__link:hover, .widgets__variants .widgets__link.active {
  background: rgba(228, 228, 228, 0.2);
}

.widgets__variants .widgets__link:hover {
  color: #11142D;
}

.widgets__variants .widgets__link.active {
  color: #355DFF;
}

.widgets__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -32px -16px 0;
}

.widgets__item {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 32px);
  flex: 0 0 calc(50% - 32px);
  max-width: calc(50% - 32px);
  margin: 32px 16px 0;
  border-radius: 24px;
  background: #ffffff;
  -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
}

.widgets__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #E4E4E4;
}

.widgets__company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widgets__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.widgets__logo img {
  width: 100%;
  min-height: 100%;
}

.widgets__category {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.widgets__info {
  font-weight: 600;
  color: #808191;
}

.widgets__time {
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0 8px;
  border-radius: 12px;
  background: #BCEAFF;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 24px;
  color: #355DFF;
}

.widgets__body {
  padding: 24px;
}

.widgets__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}

.widgets__price {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 500;
}

.widgets__item .widgets__btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 24px 24px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.widgets__item:hover .widgets__btn {
  visibility: visible;
  opacity: 1;
}

.widgets__btns {
  margin-top: 32px;
  text-align: center;
}

.widgets__chart {
  height: 92px;
  margin-bottom: -30px;
}

.widgets__chart .apexcharts-canvas svg {
  overflow: visible;
}

.widgets__box {
  position: relative;
  padding: 24px 24px 8px;
  -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  border-radius: 24px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  -o-transition: box-shadow .2s;
  transition: box-shadow .2s;
  transition: box-shadow .2s, -webkit-box-shadow .2s;
}

.widgets__box:hover {
  -webkit-box-shadow: inset 0 0 0 2px #355DFF;
  box-shadow: inset 0 0 0 2px #355DFF;
}

.widgets__box:not(:last-child) {
  margin-bottom: 24px;
}

.widgets__box .status {
  display: inline-block;
}

.widgets__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.widgets__box .widgets__price {
  margin-left: -30px;
  font-size: 16px;
  font-weight: 600;
}

.widgets__group {
  text-align: right;
}

.widgets__favorite {
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 3;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16284);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16284);
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.widgets__favorite .icon {
  font-size: 14px;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.widgets__favorite:hover .icon {
  fill: #355DFF;
}

.widgets__box:hover .widgets__favorite {
  visibility: visible;
  opacity: 1;
}

body.dark .widgets__variants .widgets__link:hover {
  background: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .widgets__variants .widgets__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .widgets__item, body.dark .widgets__box {
  background: #242731;
}

body.dark .widgets__head {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .widgets__favorite {
  background: #191B20;
}

body.dark .widgets__favorite .icon {
  fill: #ffffff;
}

body.dark .widgets__favorite:hover .icon {
  fill: #355DFF;
}

.card {
  position: relative;
}

.card__next {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.146143);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.146143);
}

.card__next .icon {
  font-size: 10px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.card__next:hover .icon {
  fill: #355DFF;
}

.card__chart_total-balance {
  height: 220px;
  margin-top: -15px;
}

.card__inner .card__title {
  margin-bottom: 16px;
}

.card__currency {
  display: inline-block;
  margin-bottom: 3px;
  padding: 0 11px;
  border-radius: 13px;
  background: #FF9F38;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #ffffff;
}

.card__inner .card__price {
  margin-bottom: 32px;
}

.card__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.card__head_mb32 {
  margin-bottom: 32px;
}

.card__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}

.card__icon, .card__arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.card__icon {
  border-radius: 50%;
}

.card__arrow .icon {
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.card__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-bottom: 20px;
  border-bottom: 1px solid #E4E4E4;
}

.card__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
}

.card__category {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.card__date {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.card__status {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.card__item .card__price {
  font-weight: 600;
}

.card__view {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 26px;
  font-weight: 600;
  color: #355DFF;
}

.card__view .icon {
  fill: #11142D;
}

.card__view:hover .icon {
  fill: #355DFF;
}

.card__filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.card__filters .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.card__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px;
}

.card__btns_mt40 {
  margin-top: 40px;
}

.card__btns .card__btn {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: auto;
  margin: 0 4px;
  padding: 0;
}

.card__reset {
  display: block;
  margin-top: 24px;
  border-top: 1px solid #E4E4E4;
  padding-top: 24px;
  line-height: 1.42857;
  font-weight: 700;
  color: #808191;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.card__reset:hover {
  color: #355DFF;
}

.card_widget {
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.card_widget:before, .card_widget:after {
  content: "";
  position: absolute;
  border-radius: 24px;
}

.card_widget:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
}

.card_widget:after {
  top: 32px;
  right: 32px;
  left: 32px;
  bottom: -32px;
  z-index: -2;
  background: #E4E4E4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.card:not(:last-child) {
  margin-bottom: 98px;
}

body.dark .card__next {
  background: #242731;
}

body.dark .card__next .icon {
  fill: #ffffff;
}

body.dark .card__next:hover .icon {
  fill: #355DFF;
}

body.dark .card__details {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .card__view .icon {
  fill: #ffffff;
}

body.dark .card__view:hover .icon {
  fill: #355DFF;
}

body.dark .card__reset {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .card_widget {
  background: #242731;
}

body.dark .card_widget:before {
  background: rgba(36, 39, 49, 0.5);
}

body.dark .card_widget:after {
  display: none;
}

.dropdown {
  position: relative;
  font-size: 0;
}

.dropdown__head {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.dropdown__head .icon {
  font-size: 12px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.dropdown__head:hover .icon {
  fill: #355DFF;
}

.dropdown__body {
  position: absolute;
  top: calc(100% + 13px);
  right: -16px;
  z-index: 2;
  min-width: 192px;
  padding: 24px;
  border: 1px solid #E4E4E4;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
}

.dropdown__link {
  position: relative;
  display: block;
  padding-left: 28px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.dropdown__link:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 13px;
  background: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23355DFF'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
  opacity: 0;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.dropdown__link:hover {
  color: #11142D;
}

.dropdown__link.selected {
  color: #355DFF;
}

.dropdown__link.selected:before {
  opacity: 1;
}

.dropdown__link:not(:last-child) {
  margin-bottom: 8px;
}

.dropdown.active {
  z-index: 5;
}

.dropdown.active .dropdown__head .icon {
  fill: #355DFF;
}

.dropdown.active .dropdown__body {
  visibility: visible;
  opacity: 1;
}

body.dark .dropdown__head {
  background: #242731;
}

body.dark .dropdown__head .icon {
  fill: #ffffff;
}

body.dark .dropdown__head:hover .icon {
  fill: #355DFF;
}

body.dark .dropdown__body {
  border-color: rgba(228, 228, 228, 0.1);
  background: #191B20;
}

body.dark .dropdown__link:hover {
  color: #ffffff;
}

body.dark .dropdown__link.selected {
  color: #355DFF;
}

.login__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.login__col {
  padding: 80px;
}

.login__col:first-child {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 44%;
  flex: 0 0 44%;
  width: 44%;
  background: #6C5DD3;
  color: #ffffff;
}

.login__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  width: 56%;
  -ms-flex-item-align: center;
  align-self: center;
}

.login__logo {
  display: inline-block;
  margin-bottom: 25px;
}

.login__col:nth-child(2) .login__logo {
  display: none;
}

.login__title {
  margin-bottom: 16px;
}

.login__preview {
  position: absolute;
  top: 270px;
  left: -370px;
  width: 1380px;
  pointer-events: none;
}

.login__preview img {
  width: 100%;
}

.login__form {
  position: relative;
  z-index: 3;
  max-width: 412px;
  margin: auto;
}

.login__stage {
  margin-bottom: 48px;
}

.login__field:not(:last-child) {
  margin-bottom: 16px;
}

.login__links:not(:last-child) {
  margin-bottom: 30px;
}

.login__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #355DFF;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.login__link .icon {
  margin-right: 12px;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.login__link:hover {
  color: #11142D;
}

.login__verify {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: 40px;
  border-radius: 12px;
  border: 2px solid #E4E4E4;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.login__verify .icon {
  margin-left: 9px;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.login__verify:hover {
  background: #E4E4E4;
}

.login__verify.success {
  border-color: #4FBF67;
  background: rgba(79, 191, 103, 0.1);
  color: #4FBF67;
}

.login__verify.success .icon {
  fill: #4FBF67;
}

.login__status {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 32px;
  height: 32px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #355DFF;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.login__status:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #355DFF;
  border-radius: 50%;
}

.login__verify.success .login__status {
  border-color: #4FBF67;
  background: #4FBF67 url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/16px auto;
}

.login__verify.success .login__status:before {
  display: none;
}

.login__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}

.login__text {
  font-size: 13px;
  font-weight: 700;
}

.login__flex .login__text {
  margin-right: 12px;
}

.login__note {
  margin-bottom: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33333;
  color: #808191;
}

.login__note a {
  color: #355DFF;
}

.login__note a:hover {
  text-decoration: underline;
}

body.dark .login__link .icon {
  fill: #ffffff;
}

body.dark .login__link:hover {
  color: #355DFF;
}

body.dark .login__link:hover .icon {
  fill: #355DFF;
}

body.dark .login__verify {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .login__verify .icon {
  fill: #ffffff;
}

body.dark .login__verify:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.1);
}

body.dark .login__verify.success {
  border-color: #4FBF67;
  color: #4FBF67;
}

body.dark .login__verify.success .icon {
  fill: #4FBF67;
}

.field {
  position: relative;
}

.field__label {
  position: absolute;
  top: 32px;
  right: 24px;
  left: 24px;
  pointer-events: none;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: .9px;
  text-transform: uppercase;
  color: #808191;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.field__label .icon {
  position: relative;
  bottom: -3px;
  margin-left: 5px;
  font-size: 14px;
  color: #11142D;
}

.field__max {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: none;
  color: #355DFF;
}

.field__input {
  width: 100%;
  height: 80px;
  padding: 18px 22px 0;
  border-radius: 12px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.field__input:focus {
  border-color: #355DFF;
  background: #ffffff;
}

.field.active .field__label {
  -webkit-transform: translateY(-12px);
  -ms-transform: translateY(-12px);
  transform: translateY(-12px);
}

.field.success:before {
  content: "";
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 23px;
  height: 18px;
  background: url("data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 18L0 10L3 7L8 12L20 0L23 3L8 18Z' fill='%234FBF67'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
}

body.dark .field__label .icon {
  fill: #ffffff;
}

body.dark .field__input {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

.checkbox {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.checkbox__in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox__tick {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #E1E1E1;
  -webkit-transition: all .25s;
  -o-transition: all .25s;
  transition: all .25s;
}

.checkbox__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 9px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
  -webkit-transition: opacity .25s;
  -o-transition: opacity .25s;
  transition: opacity .25s;
}

.checkbox__text {
  margin-left: 8px;
  padding-top: 2px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  color: #808191;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.checkbox:hover .checkbox__tick {
  border-color: #355DFF;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: #355DFF;
  border-color: #355DFF;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

.checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #11142D;
}

body.dark .checkbox__tick {
  border-color: #ffffff;
}

body.dark .checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #ffffff;
}

.notifications__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.notifications__tags .notifications__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.notifications__tags .notifications__link:hover {
  color: #11142D;
}

.notifications__tags .notifications__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #355DFF;
}

.notifications__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.notifications__select {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 244px;
  margin-right: 24px;
}

.notifications__search {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.notifications__input {
  width: 100%;
  height: 64px;
  padding: 0 64px 0 32px;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 16px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142D;
}

.notifications__input::-webkit-input-placeholder {
  color: #808191;
}

.notifications__input::-ms-input-placeholder {
  color: #808191;
}

.notifications__input::placeholder {
  color: #808191;
}

.notifications__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  font-size: 0;
}

.notifications__start .icon {
  font-size: 20px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.notifications__start:hover .icon {
  fill: #355DFF;
}

.notifications__container {
  padding: 16px 16px 40px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.notifications__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  border-radius: 16px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.notifications__item:hover {
  background: rgba(228, 228, 228, 0.25);
}

.notifications__item:hover .dropdown {
  opacity: 1;
  visibility: visible;
}

.notifications__item.disabled {
  pointer-events: none;
}

.notifications__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 50%;
}

.notifications__item.disabled .notifications__icon {
  background: #E4E4E4;
}

.notifications__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.notifications__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}

.notifications__title {
  max-width: 280px;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.notifications__item.disabled .notifications__title {
  color: #808191;
}

.notifications__time {
  position: relative;
  top: -2px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 40px;
  font-weight: 600;
  color: #808191;
}

.notifications__body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.notifications__text {
  max-height: 38px;
  overflow: hidden;
  margin-right: auto;
  font-size: 13px;
  line-height: 1.53846;
  color: #808191;
}

.notifications__item.disabled .notifications__text {
  opacity: .5;
}

.notifications__status {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-left: 40px;
  border-radius: 50%;
}

.notifications__item.disabled .notifications__status {
  background: #E4E4E4;
}

.notifications .dropdown {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.notifications .dropdown__head {
  width: 40px;
  height: 40px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.notifications .dropdown__head .icon {
  font-size: 16px;
}

body.dark .notifications__tags .notifications__link:hover {
  color: #ffffff;
}

body.dark .notifications__tags .notifications__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .notifications__input {
  background: #191B20;
  color: #ffffff;
}

body.dark .notifications__start .icon {
  fill: #ffffff;
}

body.dark .notifications__start:hover .icon {
  fill: #355DFF;
}

body.dark .notifications__container {
  background: #242731;
}

body.dark .notifications__item:hover {
  background: rgba(228, 228, 228, 0.025);
}

body.dark .notifications__item.disabled .notifications__icon {
  background: rgba(228, 228, 228, 0.1);
}

.activity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 64px 64px;
}

.activity__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.activity__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: auto;
  padding-top: 12px;
}

.activity__tags .activity__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.activity__tags .activity__link:hover {
  color: #11142D;
}

.activity__tags .activity__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #355DFF;
}

.activity__select {
  position: relative;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 230px;
  margin-left: 50px;
}

.activity__select .select {
  padding-left: 43px;
}

.activity__select .activity__icon {
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 2;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}

.activity__select .activity__icon .icon {
  font-size: 16px;
}

.activity__stage {
  margin-bottom: 32px;
}

.activity__table {
  display: table;
  width: 100%;
}

.activity__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.activity__row_head {
  background: rgba(228, 228, 228, 0.2);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #808191;
  text-transform: uppercase;
}

.activity__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.2);
}

.activity__row:not(.activity__row_head):hover {
  -webkit-box-shadow: 0 0 0 2px #355DFF;
  box-shadow: 0 0 0 2px #355DFF;
  background: none;
}

.activity__row_head .activity__cell {
  height: 40px;
}

.activity__hidden {
  display: none;
}

.activity__table.small .activity__hidden {
  display: block;
}

.activity__table.small .activity__row_head .activity__hidden {
  display: inline;
}

.activity__cell {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  padding-right: 30px;
}

.activity__cell:first-child {
  padding-left: 25px;
  border-radius: 12px 0 0 12px;
}

.activity__cell:last-child {
  border-radius: 0 12px 12px 0;
}

.activity__table.small .activity__cell:nth-child(3) {
  display: none;
}

.activity__size img {
  min-width: 15px;
}

.activity__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.activity__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.activity__logo img {
  width: 100%;
  min-height: 100%;
}

.activity__table .activity__date, .activity__transaction {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__price, .activity__address {
  font-weight: 600;
}

.activity__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 364px;
  margin-right: -364px;
  padding: 0 64px 64px 0;
  -webkit-transition: margin .2s;
  -o-transition: margin .2s;
  transition: margin .2s;
}

.activity__sidebar.visible {
  margin-right: 0;
}

.activity__wrap {
  position: relative;
}

.activity__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding-right: 30px;
}

.activity__head .activity__logo {
  width: 42px;
  height: 42px;
  margin-right: 24px;
}

.activity__info {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.activity__head .activity__date {
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.activity__close {
  position: absolute;
  top: 12px;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 0;
}

.activity__close .icon {
  font-size: 14px;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.activity__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.activity__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity__money {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
  font-weight: 600;
}

.activity__currency {
  margin-left: 20px;
  padding: 0 13px;
  border-radius: 16px;
  background: #3DBAA2;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
}

.activity__code {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__parameters {
  margin-top: 32px;
}

.activity__parameter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 32px 0;
  border-top: 1px solid #E4E4E4;
}

.activity__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 24px;
  border-radius: 50%;
  background: rgba(228, 228, 228, 0.5);
}

.activity__box {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.activity__category {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__status {
  display: inline-block;
  padding: 0 8px;
  border-radius: 12px;
  background: rgba(79, 191, 103, 0.2);
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: #4FBF67;
}

.activity__value {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

body.dark .activity__tags .activity__link:hover {
  color: #ffffff;
}

body.dark .activity__tags .activity__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .activity__select .activity__icon .icon {
  fill: #ffffff;
}

body.dark .activity__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .activity__close .icon {
  fill: #ffffff;
}

body.dark .activity__preview {
  background: #E4E4E4;
}

body.dark .activity__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

.promotions__wrapper {
  padding: 0 64px 0;
}

.promotions__title {
  margin-bottom: 48px;
}

.promotions__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.promotions__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 330px);
  flex: 0 0 calc(100% - 330px);
  max-width: calc(100% - 330px);
  padding-right: 32px;
}

.promotions__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 330px;
}

.promotions__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.promotions__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 33px 31px;
  border-radius: 16px;
  border: 1px solid #E4E4E4;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.promotions__box:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.3);
}

.promotions__box:not(:last-child) {
  margin-bottom: 16px;
}

.promotions__group_sm .promotions__box {
  padding: 33px 23px;
}

.promotions__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  margin-right: 20px;
}

.promotions__icon img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.promotions__box .promotions__date {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.promotions__info {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142D;
}

.promotions__stage {
  margin-bottom: 32px;
}

.promotions__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -40px -16px 40px;
}

.promotions__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(25% - 32px);
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 40px 16px 0;
  padding: 8px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
}

.promotions__preview img {
  width: 100%;
  border-radius: 20px;
}

.promotions__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 24px 16px 14px;
}

.promotions__item .promotions__date {
  display: inline-block;
  margin-bottom: 8px;
  padding: 0 16px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 500;
}

.promotions__item .promotions__info {
  display: block;
  margin-bottom: 16px;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.promotions__item:hover .promotions__info {
  color: #355DFF;
}

.promotions__transactions {
  margin-bottom: 32px;
}

.promotions__transactions .promotions__category {
  margin-bottom: 16px;
}

.promotions__updated {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.promotions__updated .icon {
  margin-right: 10px;
}

.promotions__table {
  margin-bottom: 24px;
}

.promotions__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.promotions__flex_head {
  background: rgba(228, 228, 228, 0.2);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #808191;
}

.promotions__flex:not(.promotions__flex_head) {
  font-weight: 600;
}

.promotions__flex:not(.promotions__flex_head):hover {
  background: rgba(255, 159, 56, 0.2);
}

.promotions__flex:not(:last-child) {
  margin-bottom: 4px;
}

.promotions__cell:first-child {
  width: 68px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 68px;
  flex: 0 0 68px;
  padding: 0 16px;
  color: #808191;
}

.promotions__cell:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  width: 80px;
}

.promotions__cell:nth-child(3) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 24px 0 12px;
  text-align: right;
}

.promotions__btns {
  text-align: center;
}

body.dark .promotions__box {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .promotions__box:hover {
  background: rgba(228, 228, 228, 0.03);
  border-color: transparent;
}

body.dark .promotions__info {
  color: #ffffff;
}

body.dark .promotions__item {
  background: #242731;
}

body.dark .promotions__flex_head {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .promotions__flex:not(.promotions__flex_head):hover {
  background: rgba(255, 159, 56, 0.2);
}

.promotion {
  padding: 8px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}

.promotion__preview img {
  width: 100%;
  border-radius: 16px;
}

.promotion__body {
  padding: 28px 24px 32px;
}

.promotion__title {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 26px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.promotion__title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 64px;
  height: 2px;
  border-radius: 1px;
  background: #6C5DD3;
}

.promotion__text {
  color: #808191;
}

.promotion__text:not(:last-child) {
  margin-bottom: 32px;
}

.promotion__note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding: 18px;
  border: 2px solid #3F8CFF;
  border-radius: 8px;
  background: rgba(63, 140, 255, 0.1);
  font-weight: 600;
}

.promotion__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
  background: #3F8CFF;
}

.promotion__category {
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.promotion__list {
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #E4E4E4;
}

.promotion__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 56px;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 600;
}

.promotion__line .icon {
  position: relative;
  bottom: -5px;
  margin-right: 16px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 16px;
}

.promotion__line:first-child {
  color: #FF6628;
}

.promotion__line:first-child .icon {
  fill: #FF6628;
}

.promotion__line:not(:last-child) {
  border-bottom: 1px solid #E4E4E4;
}

body.dark .promotion {
  background: #242731;
}

body.dark .promotion__list {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .promotion__line {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .promotion__line .icon {
  fill: #ffffff;
}

body.dark .promotion__line:first-child .icon {
  fill: #FF6628;
}

.popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow: hidden auto;
}

.close-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup {
  position: relative;
}

.popup > .popup__title {
  margin-bottom: 48px;
}

.popup__select {
  margin-bottom: 24px;
}

.popup__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px 28px;
}

.popup__nav .popup__link {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 4px;
  border: 1px solid #E4E4E4;
  border-radius: 20px;
  text-align: center;
  line-height: 38px;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.popup__nav .popup__link:hover {
  color: #355DFF;
}

.popup__nav .popup__link.active {
  background: rgba(228, 228, 228, 0.3);
  border-color: transparent;
  color: #355DFF;
}

.popup__item {
  display: none;
}

.popup__user:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E4E4E4;
}

.popup__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup__ava {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 24px;
}

.popup__ava .popup__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
  image-orientation: from-image;
}

.popup__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.popup__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
}

.popup__btns .popup__btn:not(:last-child), .popup__loading:not(:last-child) {
  margin-right: 8px;
}

.popup__loading {
  position: relative;
  overflow: hidden;
}


.popup__file {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 140px;
  opacity: 0;
}

.popup__upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 131px;
  height: 48px;
  border: 2px solid #355DFF;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  width: -webkit-fill-available;
}

.popup__loading:hover .popup__upload {
  background: #355DFF;
  color: #ffffff;
}

.popup__default {
  color: #355DFF;
}

.popup__fieldset {
  margin-bottom: 20px;
}

.popup__fieldset > .popup__field:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.popup__row:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row .popup__field {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}

.popup__label {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.popup__label_mb32 {
  margin-bottom: 32px;
}

.popup__wrap {
  position: relative;
}

.popup__input {
  width: 100%;
  height: 56px;
  padding: 0 20px;
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.popup__input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #355DFF;
  box-shadow: inset 0 0 0 2px #355DFF;
  background: #ffffff;
}

.popup__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
}

.popup__variants .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.popup__invite {
  position: relative;
  margin-bottom: 48px;
  padding: 32px;
  border-radius: 16px;
  background: -webkit-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: -o-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: radial-gradient(103.03% 103.03% at 0% 0%, #D080FF 0%, #6C5DD3 100%);
  color: #ffffff;
}

.popup__invite:before, .popup__invite:after {
  content: "";
  position: absolute;
  border-radius: 16px;
}

.popup__invite:before {
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: -16px;
  z-index: -2;
  background: rgba(108, 93, 211, 0.2);
}

.popup__invite:after {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  background: rgba(108, 93, 211, 0.5);
}

.popup__invite .popup__info {
  max-width: 380px;
  margin-bottom: 24px;
}

.popup__invite .popup__label {
  color: #ffffff;
}

.popup__invite .popup__input {
  padding-right: 56px;
  background: rgba(228, 228, 228, 0.24);
  color: #ffffff;
}

.popup__invite .popup__input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #ffffff;
  box-shadow: inset 0 0 0 2px #ffffff;
  background: rgba(228, 228, 228, 0.24);
}

.popup__copy {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 56px;
  font-size: 0;
}

.popup__copy .icon {
  font-size: 14px;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.popup__copy:hover .icon {
  opacity: .7;
}

.popup__category {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.popup__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup__currency {
  display: inline-block;
  padding: 6px 13px;
  border-radius: 16px;
  background: #3DBAA2;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #ffffff;
}

.popup__currency:not(:first-child) {
  margin-left: 20px;
}

.popup__parameters {
  margin-top: 30px;
}

.popup__parameter {
  padding: 32px 0;
}

.popup__parameter--head {
  margin-bottom: 22px;
}

.popup__parameter:last-child {
  padding-bottom: 0;
}

.popup__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.popup__body {
  max-width: 250px;
  margin-top: 12px;
  color: #808191;
}

.popup__form {
  max-width: 412px;
  margin: 60px auto;
}

.popup__title {
  margin-bottom: 32px;
}

.popup__form .popup__field:not(:last-child) {
  margin-bottom: 16px;
}

.popup__box:not(:last-child) {
  margin-bottom: 48px;
}

.popup__scan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup__scan .popup__preview {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 205px;
}

.popup__scan .popup__preview img {
  width: 100%;
}

.popup__scan .popup__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 80px;
}

.popup__scan .popup__text {
  margin-bottom: 20px;
  color: #808191;
}

.popup__scan .popup__text a {
  color: #355DFF;
}

.popup__scan .popup__text a:hover {
  text-decoration: underline;
}

.popup__numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.popup__number {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 8px;
}

.popup__number input {
  width: 100%;
  height: 80px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.5px;
  font-weight: 500;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.popup__number input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #355DFF;
  box-shadow: inset 0 0 0 2px #355DFF;
  background: #ffffff;
}

.popup__number.success input {
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 0 2px #4FBF67;
  box-shadow: inset 0 0 0 2px #4FBF67;
}

.popup__table {
  width: 100%;
}

.popup__table tr {
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.popup__table tr:first-child {
  background: rgba(228, 228, 228, 0.2);
}

.popup__table tr:not(:first-child):hover {
  background: #355DFF;
}

.popup__table tr:not(:first-child):hover .popup__close .icon {
  fill: #ffffff;
}

.popup__table tr:not(:first-child):hover th,
.popup__table tr:not(:first-child):hover td {
  color: #ffffff;
}

.popup__table tr:not(:first-child):hover th .icon,
.popup__table tr:not(:first-child):hover td .icon {
  fill: #ffffff;
}

.popup__table tr th,
.popup__table tr td {
  height: 40px;
  vertical-align: middle;
  padding-right: 16px;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.popup__table tr th:first-child,
.popup__table tr td:first-child {
  padding-left: 16px;
  border-radius: 8px 0 0 8px;
}

.popup__table tr th:last-child,
.popup__table tr td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: center;
  font-size: 0;
}

.popup__table tr th:last-child .icon,
.popup__table tr td:last-child .icon {
  font-size: 14px;
}

.popup__table tr th {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #808191;
}

.popup__table tr td {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.popup__close {
  font-size: 0;
}

.popup__close .icon {
  font-size: 14px;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.popup__flag {
  margin-right: 5px;
}

.popup .mfp-close {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%2311142d' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E") no-repeat 50% 50%/10px auto;
  -webkit-transition: -webkit-box-shadow .25s;
  transition: -webkit-box-shadow .25s;
  -o-transition: box-shadow .25s;
  transition: box-shadow .25s;
  transition: box-shadow .25s, -webkit-box-shadow .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.popup .mfp-close:hover {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.popup_settings {
  max-width: 630px;
  margin: 15px auto;
  padding: 40px 32px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
}

.popup_settings .mfp-close {
  top: 24px;
  right: 32px;
}

.mfp-container {
  padding: 20px;
}

.mfp-bg {
  background: rgba(27, 29, 33, 0.9);
}

.mfp-zoom-in .popup {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .popup {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

body.dark .popup {
  background: #242731;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

body.dark .popup__nav .popup__link {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .popup__nav .popup__link:hover {
  background: rgba(228, 228, 228, 0.1);
  border-color: transparent;
}

body.dark .popup__nav .popup__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .popup__user {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .popup__upload {
  color: #ffffff;
}

body.dark .popup__field .popup__input {
  background: #191B20;
  color: #ffffff;
}

body.dark .popup__field .popup__input:focus {
  background: none;
}

body.dark .popup__field .select {
  background: #191B20;
}

body.dark .popup__field .select.open {
  background: none;
}

body.dark .popup__variants {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .popup__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .popup__invite .popup__input {
  background: none;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

body.dark .popup__invite .popup__input:focus {
  -webkit-box-shadow: 0 0 0 2px #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
}

body.dark .popup .field__input {
  background: #191B20;
}

body.dark .popup .field__input:focus {
  background: none;
}

body.dark .popup__number input {
  background: rgba(25, 27, 32, 0.3);
  color: #ffffff;
}

body.dark .popup__table tr:first-child {
  background: rgba(228, 228, 228, 0.02);
}

body.dark .popup__table tr:not(:first-child):hover {
  background: rgba(228, 228, 228, 0.02);
}

body.dark .popup__table th:last-child .icon,
body.dark .popup__table td:last-child .icon {
  fill: #ffffff;
}

body.dark .popup .mfp-close {
  background-color: #191B20;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23ffffff' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
}

.wallets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wallets__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 64px 64px;
}

.wallets__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 364px;
  margin-right: -364px;
  padding: 0 64px 64px 0;
  -webkit-transition: margin .2s;
  -o-transition: margin .2s;
  transition: margin .2s;
}

.wallets__sidebar.visible {
  margin-right: 0;
}

.wallets__wrapper {
  position: relative;
  margin-bottom: 68px;
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.wallets__wrapper:before, .wallets__wrapper:after {
  content: "";
  position: absolute;
  border-radius: 24px;
}

.wallets__wrapper:before {
  top: 20px;
  left: 8px;
  right: 8px;
  bottom: -20px;
  z-index: -2;
  background: #E4E4E4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.wallets__wrapper:after {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: -10px;
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
}

.wallets__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.wallets__total {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 20px;
}

.wallets__group {
  position: absolute;
  right: 32px;
  left: 640px;
  bottom: 64px;
}

.wallets__wrapper.small .wallets__group {
  position: static;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 260px;
}

.wallets__wrapper .wallets__box:not(:last-child) {
  margin-bottom: 24px;
}

.wallets__wrapper .wallets__info {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.wallets__group .wallets__info {
  margin-bottom: 8px;
}

.wallets__progress {
  height: 12px;
  border-radius: 6px;
}

.wallets__btns {
  position: absolute;
  top: 32px;
  right: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wallets__wrapper.small .wallets__btns {
  display: none;
}

.wallets__btns .wallets__btn:not(:last-child) {
  margin-right: 6px;
}

.wallets__title {
  margin-bottom: 12px;
}

.wallets__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.wallets__currency {
  display: inline-block;
  padding: 6px 19px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #ffffff;
}

.wallets__currency:not(:first-child) {
  margin-left: 20px;
}

.wallets__wrapper .wallets__currency {
  background: #FF9F38;
}

.wallets__inner {
  max-width: 536px;
  margin-top: 50px;
}

.wallets__wrapper.small .wallets__inner {
  max-width: 100%;
}

.wallets__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -24px -12px 0;
}

.wallets__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 24px);
  flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 24px 12px 0;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #E4E4E4;
}

.wallets__icon {
  margin-right: 12px;
}

.wallets__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.wallets__price {
  margin-top: 2px;
  font-weight: 600;
  color: #4FBF67;
}

.wallets__wrap {
  position: relative;
}

.wallets__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding-right: 30px;
}

.wallets__head .wallets__logo {
  width: 42px;
  height: 42px;
  margin-right: 16px;
}

.wallets__head .wallets__info {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.wallets__company {
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.wallets__close {
  position: absolute;
  top: 12px;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 0;
}

.wallets__close .icon {
  font-size: 14px;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.wallets__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.wallets__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wallets__money {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
  font-weight: 600;
}

.wallets__wrap .wallets__currency {
  background: #627EEA;
}

.wallets__code {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.wallets__parameters {
  margin-top: 32px;
}

.wallets__parameter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 0;
  border-top: 1px solid #E4E4E4;
}

.wallets__parameter:last-child {
  padding-bottom: 0;
}

.wallets__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 32px;
  border-radius: 50%;
}

.wallets__parameter .wallets__box {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.wallets__category {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.wallets .card {
  margin-top: 48px;
}

body.dark .wallets__wrapper {
  background: #242731;
}

body.dark .wallets__wrapper:before {
  display: none;
}

body.dark .wallets__wrapper:after {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: -10px;
  z-index: -1;
  background: rgba(36, 39, 49, 0.5);
}

body.dark .wallets__item {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .wallets__close .icon {
  fill: #ffffff;
}

body.dark .wallets__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

.balances__title {
  margin-bottom: 32px;
}

.balances__table {
  display: table;
  width: 100%;
}

.balances__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.balances__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.2);
}

.balances__row:not(.balances__row_head):hover {
  background: none;
  -webkit-box-shadow: 0 0 0 2px #355DFF;
  box-shadow: 0 0 0 2px #355DFF;
}

.balances__cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 24px;
  font-family: 'Inter';
  font-size: 14px;
}

.balances__cell:first-child {
  border-radius: 12px 0 0 12px;
}

.balances__text {
  line-height: 1.2;
}

.balances__cell:nth-child(2) {
  font-weight: 600;
  color: #808191;
}

.balances__cell:nth-child(3) {
  font-weight: 600;
  color: #808191;
}

.balances__cell:last-child {
  padding-right: 24px;
  border-radius: 0 12px 12px 0;
}

.balances__table.small .balances__cell:nth-child(4),
.balances__table.small .balances__cell:nth-child(5) {
  display: none;
}

.balances__row_head .balances__cell {
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.balances__row_head .balances__cell:first-child {
  border-radius: 8px 0 0 8px;
}

.balances__row_head .balances__cell:last-child {
  border-radius: 0 8px 8px 0;
}

.balances__row:not(.balances__row_head) .balances__cell {
  height: 80px;
}

.balances__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.balances__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.balances__logo img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.balances__number {
  font-weight: 600;
}

.balances__price {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.balances__btns {
  margin-top: 32px;
  text-align: center;
}

body.dark .balances__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.04);
}

.operations__form, .operations__wrap {
  display: none;
}

.operations__field, .operations__balance, .operations__action {
  margin-bottom: 16px;
}

.operations__balance {
  padding: 20px 22px;
  border-radius: 12px;
  background: rgba(79, 191, 103, 0.2);
}

.operations__label {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: .9px;
  text-transform: uppercase;
  color: #808191;
}

.operations__form .operations__counter {
  margin-bottom: 2px;
  font-weight: 600;
}

.operations__price {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.operations__action .operations__field {
  margin-bottom: 0;
}

.operations__action .operations__field .field__input {
  border-radius: 12px 12px 0 0;
}

.operations__note {
  padding: 0 20px;
  background: #FF9F38;
  border-radius: 0 0 12px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
}

.operations__item {
  padding: 24px;
  border-radius: 24px;
  background: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.operations__item:not(:last-child) {
  margin-bottom: 24px;
}

.operations__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.operations__item .operations__box {
  text-align: right;
}

.operations__item .operations__counter {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.operations__title {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.operations__item > .operations__title {
  margin-bottom: 8px;
}

.operations__text {
  margin-bottom: 32px;
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.operations__text strong {
  font-weight: 400;
  color: #11142D;
}

.operations__code {
  position: relative;
  margin-bottom: 36px;
}

.operations__input {
  width: 100%;
  height: 48px;
  padding: 0 48px 0 16px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142D;
}

.operations__copy {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  font-size: 0;
}

.operations__copy .icon {
  font-size: 14px;
  fill: #355DFF;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.operations__copy:hover .icon {
  opacity: .7;
}

.operations__preview {
  max-width: 105px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.operations__preview img {
  width: 100%;
}

.operations__info {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.operations__info a {
  color: #355DFF;
}

.operations__info a:hover {
  text-decoration: none;
}

body.dark .operations__item {
  background: #242731;
}

body.dark .operations__input {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

body.dark .operations__text strong {
  color: #ffffff;
}

.search__container {
  position: relative;
  padding: 48px;
  border-radius: 24px;
  background: -webkit-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: -o-radial-gradient(0% 0%, 103.03% 103.03%, #D080FF 0%, #6C5DD3 100%);
  background: radial-gradient(103.03% 103.03% at 0% 0%, #D080FF 0%, #6C5DD3 100%);
  color: #ffffff;
}

.search__wrap {
  position: relative;
  z-index: 3;
  max-width: 380px;
  margin-bottom: 36px;
}

.search__title {
  margin-bottom: 16px;
}

.search__form {
  position: relative;
  z-index: 3;
  max-width: 496px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.search__input {
  width: 100%;
  height: 64px;
  padding: 0 64px 0 30px;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142D;
}

.search__input::-webkit-input-placeholder {
  color: #808191;
}

.search__input::-ms-input-placeholder {
  color: #808191;
}

.search__input::placeholder {
  color: #808191;
}

.search__btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  font-size: 0;
}

.search__btn .icon {
  font-size: 20px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.search__btn:hover .icon {
  fill: #355DFF;
}

.search__preview {
  position: absolute;
  /*top: -27px;*/
  top: auto;
  left: 50%;
  bottom: -22%;
  width: 640px;
}


.search__preview img {
  width: 100%;
}

.search:not(:last-child) {
  margin-bottom: 56px; /*136*/
}

.categories__title {
  margin-bottom: 48px;
}

.categories__container {
  margin: 0 -12px;
}

.categories__item {
  display: block;
  height: 120%;
  margin: 0 22px;
  padding: 36px 32px 32px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.categories__item:hover {
  -webkit-box-shadow: 0 0 0 4px #355DFF;
  box-shadow: 0 0 0 4px #355DFF;
  color: #355DFF;
}

.categories__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 112px;
  height: 112px;
  margin: 0 auto 40px;
  border-radius: 50%;
}

.categories__preview img {
  max-height: 32px;
}

.categories .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categories .owl-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.categories .owl-item.active {
  visibility: visible;
  opacity: 1;
}

.categories .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.categories .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 46px;
}

.categories .owl-carousel button.owl-dot {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: #E4E4E4;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.categories .owl-carousel button.owl-dot.active {
  background: #355DFF;
}

.categories .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 4px;
}

.categories:not(:last-child) {
  margin-bottom: 80px;
}

body.dark .categories__item {
  background: #242731;
  color: #ffffff;
}

body.dark .categories .owl-carousel button.owl-dot {
  background: rgba(255, 255, 255, 0.5);
}

body.dark .categories .owl-carousel button.owl-dot.active {
  background: #355DFF;
}

.prices__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 48px;
}

.prices__table {
  display: table;
  width: 100%;
}

.prices__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.prices__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.2);
}

.prices__row:not(.prices__row_head):hover {
  background: none;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
}

.prices__cell {
  display: table-cell;
  vertical-align: middle;
  height: 72px;
  padding-left: 16px;
  font-weight: 600;
}

.prices__cell:first-child {
  border-radius: 12px 0 0 12px;
  font-size: 0;
}

.prices__cell:nth-child(3) {
  font-weight: 600;
  color: #808191;
}

.prices__cell:last-child {
  padding-right: 16px;
  border-radius: 0 12px 12px 0;
}

.prices__row_head .prices__cell {
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.prices__row_head .prices__cell:first-child {
  border-radius: 8px 0 0 8px;
}

.prices__row_head .prices__cell:last-child {
  border-radius: 0 8px 8px 0;
}

.prices__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.prices__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.prices__logo img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.prices__chart {
  position: relative;
  bottom: -4px;
  width: 112px;
  height: 30px;
}

.prices__chart .apexcharts-canvas svg {
  overflow: visible;
}

.prices__btns {
  margin-top: 40px;
  text-align: center;
}

body.dark .prices__row {
  color: #ffffff;
}

body.dark .prices__row:nth-child(2n+1) {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .prices__row:not(.prices__row_head):hover {
  background: #242731;
}

.details__container {
  position: relative;
  padding: 32px 32px 18px;
  background: #ffffff;
  border-radius: 32px;
}

.details__container:before {
  content: "";
  position: absolute;
  top: 14px;
  left: 20px;
  right: 20px;
  bottom: -14px;
  z-index: -1;
  border-radius: 32px;
  background: #E4E4E4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.details__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.details__company {
  position: relative;
  z-index: 5;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 245px;
  margin-right: auto;
}

.details__head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 30px;
}

.details__logo {
  margin-right: 16px;
}

.details__logo img {
  width: 100%;
  min-height: 100%;
}

.details__head .details__logo {
  width: 56px;
  height: 56px;
}

.details__head .details__info {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__currency {
  color: #808191;
}

.details__head .details__currency {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__desc {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.details__body {
  position: absolute;
  top: calc(100% + 20px);
  left: -16px;
  right: -16px;
  padding: 16px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.details__company.active .details__body {
  visibility: visible;
  opacity: 1;
}

.details__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 12px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.details__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.details__item:not(:last-child) {
  margin-bottom: 4px;
}

.details__item .details__logo {
  width: 36px;
  height: 36px;
}

.details__item .details__info {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #11142D;
}

.details__item .details__currency {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.details__btns .details__btn:not(:last-child) {
  margin-right: 16px;
}

.details__burger {
  position: absolute;
  top: 6px;
  right: 0;
  z-index: 2;
  width: 20px;
  height: 20px;
}

.details__burger:before, .details__burger:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin: 6px 0;
  border-radius: 1px;
  background: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.details__company.active .details__burger:before {
  -webkit-transform: translateY(4px) rotate(45deg);
  -ms-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg);
}

.details__company.active .details__burger:after {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  -ms-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg);
}

.details__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.details__money {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -1px;
  font-weight: 600;
}

.details__status {
  margin-left: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__chart {
  height: 330px;
  margin: -32px -32px 24px -24px;
}

.details__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -32px 32px;
  border-top: 1px solid #E4E4E4;
}

.details__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  padding: 36px 28px;
  border-bottom: 1px solid #E4E4E4;
}

.details__box:nth-child(2n+1) {
  border-right: 1px solid #E4E4E4;
}

.details__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
  border: 1px solid #E4E4E4;
}

.details__category {
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.details__category .icon {
  position: relative;
  bottom: -2px;
  margin-left: 8px;
  font-size: 12px;
  fill: #808191;
}

.details__price {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__title {
  margin-bottom: 24px;
}

.details__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.details__col:first-child {
  max-width: 285px;
  margin-right: 40px;
}

.details__text {
  font-size: 13px;
  line-height: 1.53846;
  color: #808191;
}

.details__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.details__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 1.53846;
  font-weight: 700;
  color: #355DFF;
}

.details__link .icon {
  margin-right: 12px;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.details__link:hover .icon {
  fill: #355DFF;
}

.details__link:not(:last-child) {
  margin-bottom: 16px;
}

body.dark .details__container {
  background: #242731;
}

body.dark .details__container:before {
  display: none;
}

body.dark .details__burger:before, body.dark .details__burger:after {
  background: #ffffff;
}

body.dark .details__chart .apexcharts-xaxis line,
body.dark .details__chart .apexcharts-grid line {
  stroke: rgba(228, 228, 228, 0.1);
}

body.dark .details__body {
  background: #191B20;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

body.dark .details__item:hover {
  background: #242731;
}

body.dark .details__list, body.dark .details__box, body.dark .details__icon {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .details__item .details__info {
  color: #ffffff;
}

body.dark .details__link .icon {
  fill: #ffffff;
}

body.dark .details__link:hover .icon {
  fill: #355DFF;
}

.exchange__top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 48px;
  border-radius: 16px;
  border: 1px solid #E4E4E4;
  background: #ffffff;
}

.exchange__top:after {
  content: "";
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 38px;
  border-radius: 16px;
  background: #E4E4E4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.exchange__cell {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 35px 24px;
  border-right: 1px solid #E4E4E4;
}

.exchange__cell:last-child {
  border: none;
}

.exchange__company {
  position: relative;
  z-index: 5;
}

.exchange__head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.exchange__logo {
  margin-right: 16px;
}

.exchange__logo img {
  width: 100%;
  min-height: 100%;
}

.exchange__head .exchange__logo {
  width: 40px;
  height: 40px;
}

.exchange__currency {
  font-weight: 600;
}

.exchange__info {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.exchange__arrow {
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 0;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.exchange__arrow .icon {
  font-size: 7px;
}

.exchange__company.active .exchange__arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.exchange__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.exchange__body {
  position: absolute;
  top: calc(100% + 16px);
  left: -24px;
  right: -24px;
  padding: 12px 8px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.exchange__company.active .exchange__body {
  visibility: visible;
  opacity: 1;
}

.exchange__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 12px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.exchange__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.exchange__item:not(:last-child) {
  margin-bottom: 4px;
}

.exchange__item .exchange__logo {
  width: 36px;
  height: 36px;
}

.exchange__item .exchange__currency {
  color: #11142D;
}

.exchange .status {
  margin-top: 5px;
}

.exchange__price {
  font-weight: 600;
}

.exchange__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.exchange__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 64px;
}

.exchange__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 340px;
}

.exchange .orders:not(:last-child) {
  margin-bottom: 48px;
}

.exchange .actions:not(:last-child) {
  margin-bottom: 56px;
}

.exchange__banner {
  position: relative;
  min-height: 335px;
  padding: 32px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: #ffffff;
}

.exchange__banner:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: rgba(108, 93, 211, 0.5);
}

.exchange__date {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.exchange__title {
  margin-bottom: 16px;
}

.exchange__banner .exchange__btn {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200219);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200219);
}

.exchange__foot {
  display: none;
  margin-top: auto;
  padding-top: 32px;
}

.exchange__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -16px;
  padding: 20px 16px 42px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border: 1px solid #E4E4E4;
  border-radius: 20px 20px 0 0;
}

.exchange__menu .exchange__link {
  position: relative;
  font-size: 0;
}

.exchange__menu .exchange__link .icon {
  font-size: 24px;
  fill: #808191;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.exchange__menu .exchange__link:before {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FF6628;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.exchange__menu .exchange__link.note:before {
  visibility: visible;
  opacity: 1;
}

.exchange__menu .exchange__link.active .icon {
  fill: #355DFF;
}

body.dark .exchange__top {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .exchange__top:after {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .exchange__cell {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .exchange__arrow .icon {
  fill: #ffffff;
}

body.dark .exchange__body {
  background: #191B20;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

body.dark .exchange__item:hover {
  background: #242731;
}

body.dark .exchange__item .exchange__currency {
  color: #ffffff;
}

body.dark .exchange__menu {
  border-color: rgba(228, 228, 228, 0.1);
}

.market {
  border-radius: 32px;
  border: 1px solid #E4E4E4;
}

.market__tabs {
  border-bottom: 1px solid #E4E4E4;
}

.market__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #E4E4E4;
}

.market__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
}

.market__menu .market__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.market__menu .market__link:hover {
  color: #11142D;
}

.market__menu .market__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #355DFF;
}

.market__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.market__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  color: #11142D;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
}

.market__action .icon {
  font-size: 16px;
  fill: #11142D;
  -webkit-transition: fill .2s;
  -o-transition: fill .2s;
  transition: fill .2s;
}

.market__action .icon:not(:last-child) {
  margin-right: 8px;
}

.market__action:hover {
  color: #355DFF;
}

.market__action:hover .icon {
  fill: #355DFF;
}

.market__action:not(:last-child) {
  margin-right: 32px;
}

.market__item {
  display: none;
  padding: 24px;
}

.market__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -12px 0 0 -24px;
}

.market__variant {
  margin: 12px 0 0 24px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.market__chart_price {
  margin-top: 10px;
  margin-left: -12px;
}

.market__chart_deep {
  margin-top: 20px;
  margin-right: -10px;
  margin-left: -30px;
  margin-bottom: 20px;
}

.market__top {
  padding: 24px;
}

.market__top .market__menu {
  margin-bottom: 24px;
}

.market__table {
  display: table;
  width: 100%;
}

.market__row {
  display: table-row;
  position: relative;
  min-height: 200px;
}

.market__cell {
  display: table-cell;
  height: 40px;
  padding-left: 24px;
  vertical-align: middle;
  border-top: 1px solid #E4E4E4;
}

.market__cell:last-child {
  padding-right: 24px;
}

.market__row_head .market__cell {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.market__row:not(.merket__row_head) .market__cell {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

body.dark .market {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .market__head {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .market__menu .market__link:hover {
  color: #ffffff;
}

body.dark .market__menu .market__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .market__action {
  color: #ffffff;
}

body.dark .market__action .icon {
  fill: #ffffff;
}

body.dark .market__action:hover {
  color: #355DFF;
}

body.dark .market__action:hover .icon {
  fill: #355DFF;
}

body.dark .market__tabs, body.dark .market__cell {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .market__chart .apexcharts-xaxis line,
body.dark .market__chart .apexcharts-grid line {
  stroke: rgba(228, 228, 228, 0.1);
}

.orders__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.orders__title {
  margin-right: auto;
}

.orders__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.orders__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 8px;
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  -o-transition: box-shadow .2s;
  transition: box-shadow .2s;
  transition: box-shadow .2s, -webkit-box-shadow .2s;
}

.orders__link span {
  width: 100%;
  height: 2px;
  border-radius: 1;
}

.orders__link span:last-child {
  width: 50%;
}

.orders__link span:not(:last-child) {
  margin-bottom: 3px;
}

.orders__link.active {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.orders__link:not(:last-child) {
  margin-right: 24px;
}

.orders__table {
  border-radius: 12px;
  border: 1px solid #E4E4E4;
  overflow: hidden;
}

.orders__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.orders__row_head {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.orders__row:not(.orders__row_head) {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.orders__cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 32px;
  padding-right: 12px;
}

.orders__cell:first-child {
  position: relative;
  padding-left: 12px;
}

.orders__cell:not(:first-child) {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.orders__row_head .orders__cell {
  height: 24px;
}

.orders__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #E4E4E4;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.orders__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.orders__up {
  color: #FF7A68;
}

.orders__up .orders__bg {
  background: rgba(255, 122, 104, 0.3);
}

.orders__down {
  color: #3DBAA2;
}

.orders__down .orders__bg {
  background: rgba(61, 186, 162, 0.3);
}

body.dark .orders__link.active {
  background: #191B20;
}

body.dark .orders__table {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .orders__price {
  border-color: rgba(228, 228, 228, 0.1);
}

.actions {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 24px 15px;
  border-radius: 24px;
  background: #ffffff;
}

.actions:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 12px;
  right: 12px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: #E4E4E4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.actions__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px 20px;
}

.actions__link {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 4px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.actions__link:hover {
  color: #11142D;
}

.actions__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #355DFF;
}

.actions__fieldset {
  margin-bottom: 48px;
}

.actions__field {
  position: relative;
}

.actions__field:not(:last-child) {
  margin-bottom: 12px;
}

.actions__label {
  position: absolute;
  top: 12px;
  left: 24px;
  z-index: 3;
  pointer-events: none;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.actions .select {
  padding-top: 34px;
  padding-left: 24px;
  border-radius: 12px;
  line-height: 1;
}

.actions__input {
  width: 100%;
  height: 64px;
  padding: 26px 80px 10px 24px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #11142D;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.actions__input:focus {
  background: #ffffff;
  -webkit-box-shadow: 0 0 0 2px #355DFF;
  box-shadow: 0 0 0 2px #355DFF;
}

.actions__currency {
  position: absolute;
  top: 28px;
  right: 24px;
  pointer-events: none;
  text-align: right;
  font-weight: 700;
}

body.dark .actions {
  background: #242731;
}

body.dark .actions:before {
  display: none;
}

body.dark .actions__link:hover {
  color: #ffffff;
}

body.dark .actions__link.active {
  background: #191B20;
  color: #355DFF;
}

body.dark .actions__input {
  background: #191B20;
  color: #ffffff;
}

body.dark .actions__input:focus {
  background: none;
}

body.dark .actions .select {
  background: #191B20;
}

body.dark .actions .select.open {
  background: none;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 72px;
  line-height: 1.11111;
  font-weight: 600;
  letter-spacing: -1px;
}

.title_sm {
  font-size: 64px;
  line-height: 1.125;
}

.title_big {
  font-size: 96px;
  line-height: 1.16667;
}

.h1,
.h2,
.h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.h4,
.h5,
.h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.h1 {
  font-size: 56px;
  line-height: 1.14286;
  letter-spacing: -1px;
}

.h2 {
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -1px;
}

.h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
}

.h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.5px;
}

.h5 {
  font-size: 24px;
  line-height: 1.33333;
}

.h6 {
  font-size: 18px;
  line-height: 1.33333;
}

.color-green {
  color: #4FBF67;
}

.color-blue {
  color: #355DFF;
}

.color-red {
  color: #FF6628;
}

.color-gray {
  color: #808191;
}

.color-orange {
  color: #FF9F38;
}

.bg-blue {
  background: #355DFF;
}

.bg-blue-light {
  background: #3F8CFF;
}

.bg-blue-gradient {
  background: -webkit-linear-gradient(303.71deg, #B7EAFF 14.84%, #57B1E4 92.53%);
  background: -o-linear-gradient(303.71deg, #B7EAFF 14.84%, #57B1E4 92.53%);
  background: linear-gradient(146.29deg, #B7EAFF 14.84%, #57B1E4 92.53%);
}

.bg-green {
  background: #4FBF67;
}

.bg-green-opacity {
  background: rgba(79, 191, 103, 0.15);
}

.bg-green-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#86E1A0), to(#4FBF67));
  background: -webkit-linear-gradient(top, #86E1A0 0%, #4FBF67 100%);
  background: -o-linear-gradient(top, #86E1A0 0%, #4FBF67 100%);
  background: linear-gradient(180deg, #86E1A0 0%, #4FBF67 100%);
}

.bg-orange {
  background: #FF9F38;
}

.bg-orange-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#FFCE6A), to(#FF9F38));
  background: -webkit-linear-gradient(top, #FFCE6A 0%, #FF9F38 100%);
  background: -o-linear-gradient(top, #FFCE6A 0%, #FF9F38 100%);
  background: linear-gradient(180deg, #FFCE6A 0%, #FF9F38 100%);
}

.bg-purple {
  background: #6C5DD3;
}

.bg-purple-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#A596EB), to(#6C5DD3));
  background: -webkit-linear-gradient(top, #A596EB 0%, #6C5DD3 100%);
  background: -o-linear-gradient(top, #A596EB 0%, #6C5DD3 100%);
  background: linear-gradient(180deg, #A596EB 0%, #6C5DD3 100%);
}

.bg-pink {
  background: #FF98E5;
}

.bg-pink-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#FFC8F4), to(#FF98E5));
  background: -webkit-linear-gradient(top, #FFC8F4 0%, #FF98E5 100%);
  background: -o-linear-gradient(top, #FFC8F4 0%, #FF98E5 100%);
  background: linear-gradient(180deg, #FFC8F4 0%, #FF98E5 100%);
}

.bg-red {
  background: #FF6628;
}

.bg-red-opacity {
  background: rgba(255, 102, 40, 0.15);
}

.positive {
  color: #3DBAA2;
}

.negative {
  color: #FF7A68;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.desktop-show {
  display: none !important;
}

.tablet-show {
  display: none !important;
}

.mobile-show {
  display: none !important;
}

@media only screen and (min-width: 768px) {
  .exchange__top {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .exchange .orders:not(:last-child) {
    display: block !important;
  }
  .exchange .actions:not(:last-child) {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .market__tabs {
    display: block !important;
  }
  .market__transactions {
    display: block !important;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media only screen and (max-width: 1799px) {
  .slider_home .slider__preview {
    left: 20%;
  }
}

@media only screen and (max-width: 1579px) {
  .slider_home .slider__preview {
    left: 20px;
  }
  .notifications__text {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1419px) {
  .page__row {
    padding: 0 64px 64px;
  }
  .page__col {
    padding: 0;
  }
  .page__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
    max-width: calc(100% - 290px);
    padding-right: 64px;
  }
  .page__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 290px;
    flex: 0 0 290px;
    width: 290px;
    border: none;
  }
  .header_border:before {
    display: none;
  }
  .widgets__variants .widgets__link {
    min-width: auto;
    padding: 0 8px;
  }
  .activity__table.small .activity__hidden {
    display: none;
  }
  .activity__table.small .activity__row_head .activity__hidden {
    display: none;
  }
  .activity__cell {
    padding-right: 25px;
  }
  .activity__table.small .activity__cell:nth-child(3) {
    display: table-cell;
  }
  .activity__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 20px;
    background: rgba(27, 29, 33, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
  .activity__sidebar.visible {
    visibility: visible;
    opacity: 1;
  }
  .activity__wrap {
    width: 100%;
    max-width: 630px;
    margin: auto;
    padding: 32px;
    border-radius: 24px;
    background: #ffffff;
  }
  .activity__head {
    padding-right: 60px;
  }
  .activity__close {
    top: 26px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  .promotions__cell:first-child {
    padding: 0 16px;
  }
  .promotions__cell:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    width: 70px;
  }
  .promotions__cell:nth-child(3) {
    padding: 0 16px 0 10px;
  }
  .wallets__sidebar {
    width: 353px;
  }
  .wallets__wrapper.small .wallets__group {
    width: 200px;
  }
  .wallets__flex .wallets__number {
    font-size: 32px;
  }
  .search__preview {
    left: 45%;
    width: 500px;
  }
  .categories__item {
    padding: 32px 24px;
  }
  .exchange__cell {
    padding: 24px 16px;
  }
  .exchange__container {
    padding-right: 48px;
  }
}

@media only screen and (max-width: 1339px) {
  .page__row {
    padding: 0 48px 64px;
  }
  .page__col:first-child {
    padding-right: 48px;
  }
  .header {
    padding: 48px 48px 36px;
  }
  .slider_promotions .slider__item {
    min-height: 420px;
  }
  .slider_promotions .slider__preview {
    bottom: -30%;
  }
  .activity__container {
    padding: 0 48px 64px;
  }
  body.dark .activity__wrap {
    background: #242731;
  }
  .promotions__wrapper {
    padding: 0 48px 64px;
  }
  .promotions__title {
    margin-bottom: 40px;
  }
  .promotions__row {
    display: block;
  }
  .promotions__col:first-child {
    max-width: 100%;
    margin-bottom: 32px;
    padding: 0;
  }
  .promotions__col:nth-child(2) {
    width: auto;
  }
  .promotions__group_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    margin: 0 -48px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .promotions__group_row::-webkit-scrollbar {
    display: none;
  }
  .promotions__group_row:before, .promotions__group_row:after {
    content: "";
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 48px;
    height: 1px;
  }
  .promotions__group_row .promotions__box {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 360px;
  }
  .promotions__group_row .promotions__box:not(:last-child) {
    margin: 0 32px 0 0;
  }
  .wallets__container {
    padding: 0 48px 64px;
  }
  .wallets__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 20px;
    background: rgba(27, 29, 33, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
  .wallets__sidebar.visible {
    visibility: visible;
    opacity: 1;
  }
  .wallets__group {
    position: static;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: calc(50% - 12px);
  }
  .wallets__wrapper.small .wallets__group {
    width: calc(50% - 12px);
  }
  .wallets__wrapper .wallets__box:not(:last-child) {
    margin-bottom: 16px;
  }
  .wallets__btns {
    display: none;
  }
  .wallets__inner {
    max-width: 100%;
  }
  .wallets__wrap {
    width: 100%;
    max-width: 630px;
    margin: auto;
    padding: 32px;
    border-radius: 24px;
    background: #ffffff;
  }
  .wallets__head {
    padding-right: 50px;
  }
  .wallets__close {
    top: 26px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  .wallets__parameters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 32px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: #E4E4E4;
  }
  .wallets__parameter {
    padding: 0;
    border: none;
    margin-left: auto;
  }
  /*.wallets__parameter:last-child {
    margin-left: auto;
  }*/
  .wallets .card {
    margin-top: 32px;
  }
  body.dark .wallets__wrap {
    background: #242731;
  }
  body.dark .wallets__parameters {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .balances__table.small .balances__cell:nth-child(4),
  .balances__table.small .balances__cell:nth-child(5) {
    display: table-cell;
  }
  body.dark .operations__item {
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  .search__form {
    max-width: 450px;
  }
  .prices__cell:nth-child(3) {
    display: none;
  }
  .details__company {
    width: 230px;
  }
  .details__head .details__info {
    font-size: 21px;
  }
  .details__money {
    font-size: 40px;
  }
  .details__box {
    padding: 32px;
  }
  .details__price {
    font-size: 16px;
  }
  .exchange__cell {
    padding: 24px;
  }
  .exchange__cell:nth-child(4) {
    border: none;
  }
  .exchange__cell:nth-child(n+5) {
    display: none;
  }
}

@media only screen and (max-width: 1259px) {
  .page__row {
    display: block;
    padding: 0 64px 64px;
  }
  .page__col:first-child {
    max-width: 100%;
    margin-bottom: 64px;
    padding: 0;
  }
  .page__col:nth-child(2) {
    width: 100%;
  }
  .sidebar {
    padding-top: 120px;
  }
  .sidebar__head {
    height: 120px;
    padding: 0;
  }
  .sidebar.small.active .sidebar__mail {
    display: none;
  }
  .header {
    padding: 36px 64px;
  }
  .slider:not(:last-child) {
    margin-bottom: 48px;
  }
  .widgets__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -24px 0;
  }
  .widgets__wrap .widgets__box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 48px);
    flex: 0 0 calc(50% - 48px);
    width: calc(50% - 48px);
    margin: 32px 24px 0;
  }
  .widgets__wrap .widgets__box:not(:last-child) {
    margin-bottom: 0;
  }
  .card__chart_total-balance {
    margin-top: 0;
  }
  .card__inner {
    text-align: left;
  }
  .card__inner .card__price {
    margin-bottom: 24px;
  }
  .card_widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .card_widget .card__chart {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 240px;
    margin-right: 24px;
  }
  .card:not(:last-child) {
    margin-bottom: 64px;
  }
  .login__col {
    padding: 80px 48px;
  }
  .login__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
  }
  .login__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
  }
  .login__preview {
    left: -130%;
  }
  .activity__container {
    padding: 0 64px 64px;
  }
  .promotions__wrapper {
    padding: 0 64px 64px;
  }
  .promotions__group_row {
    margin: 0 -64px;
  }
  .promotions__group_row:before, .promotions__group_row:after {
    width: 64px;
  }
  .promotions__group_row .promotions__box {
    width: 330px;
  }
  .promotions__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
  }
  .promotions__transactions, .promotions__wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
  }
  .promotions_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
  }
  .wallets__container {
    padding: 0 64px 64px;
  }
  .search__container {
    padding: 32px;
  }
  .search__form {
    max-width: 360px;
  }
  .search__preview {
    width: 500px;
  }
  .details__company {
    width: 250px;
  }
  .exchange__top {
    margin-bottom: 32px;
  }
  .exchange__row {
    display: block;
  }
  .exchange__container {
    margin-bottom: 48px;
    padding: 0;
  }
  .exchange__sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: auto;
    margin: 0 -16px;
  }
  .exchange .orders:not(:last-child) {
    margin-bottom: 0;
  }
  .exchange .actions:not(:last-child) {
    margin-bottom: 0;
  }
  .exchange .orders,
  .exchange .actions {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
  }
  .exchange__banner {
    display: none;
  }
  .actions__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .actions__btn {
    margin-top: auto;
  }
  .desktop-hide {
    display: none !important;
  }
  .desktop-show {
    display: block !important;
  }
  .desktop-text-right {
    text-align: right !important;
  }
}

@media (hover: hover) {
  .switch:hover {
    opacity: .8;
  }
}

@media only screen and (max-width: 1179px) {
  .page__content {
    padding-left: 96px;
  }
  .sidebar {
    width: 96px;
  }
  .sidebar__logo {
    display: none;
  }
  .sidebar__toggle svg {
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .sidebar__item {
    padding: 0 16px;
  }
  .sidebar__mail {
    display: none;
  }
  .sidebar__bottom {
    padding: 0 12px;
  }
  .sidebar .switch__in {
    padding: 0;
  }
  .sidebar .switch__icon {
    left: 46px;
  }
  .sidebar .switch__icon .icon {
    font-size: 16px;
  }
  .sidebar .switch__input:checked + .switch__in .switch__icon {
    left: 10px;
  }
  .sidebar.active {
    width: 255px;
    -webkit-box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
  }
  .sidebar.active .sidebar__logo {
    display: inline-block;
  }
  .active .sidebar__toggle svg {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .sidebar.active .sidebar__item {
    padding: 0 20px;
  }
  .sidebar.active .sidebar__bottom {
    padding: 0 20px;
  }
  .sidebar.active .switch__in {
    padding-left: 46px;
  }
  .sidebar.active .switch__icon {
    left: 0;
  }
  .sidebar.active .switch__icon .icon {
    font-size: 24px;
  }
  .sidebar.active .switch__input:checked + .switch__in .switch__icon {
    left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .page__content {
    padding-left: 0;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  .page__head {
    margin-top: 0;
  }
  .page.wide .page__content,
  .page.full .page__content {
    padding: 0;
  }
  .page.full.wide .page__content {
    padding: 0;
  }
  .page.toggle .page__content {
    -webkit-transform: translateX(255px);
    -ms-transform: translateX(255px);
    transform: translateX(255px);
  }
  .sidebar {
    width: 255px;
    padding-bottom: 90px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
  .sidebar__head .sidebar__close {
    display: inline-block;
  }
  .sidebar__logo {
    display: inline-block;
  }
  .sidebar__toggle {
    display: none;
  }
  .sidebar__item {
    padding: 0 20px;
  }
  .sidebar__mail {
    display: none;
  }
  .sidebar__search {
    display: block;
  }
  .sidebar__start {
    width: 56px;
  }
  .sidebar__bottom {
    padding: 0 20px;
  }
  .sidebar .switch__in {
    padding-left: 46px;
  }
  .sidebar .switch__icon {
    left: 0;
  }
  .sidebar .switch__icon .icon {
    font-size: 24px;
  }
  .sidebar .switch__input:checked + .switch__in .switch__icon {
    left: 0;
  }
  .sidebar.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar.small.active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header {
    padding: 32px 64px;
  }
  .header__search {
    display: none;
  }
  .header__logo {
    display: inline-block;
  }
  .header__indicator {
    display: none;
  }
  .header__toggle {
    display: inline-block;
  }
  .widgets__wrap {
    margin: -24px -12px 0;
  }
  .widgets__wrap .widgets__box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 24px);
    flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
    margin: 24px 12px 0;
  }
  .login__row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px;
  }
  .login__col {
    padding: 0;
  }
  .login__col:first-child {
    display: none;
  }
  .login__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 500px;
    flex: 0 0 500px;
    width: 500px;
  }
  .login__logo img {
    width: 60px;
  }
  .login__col:nth-child(2) .login__logo {
    display: inline-block;
  }
  .login__preview {
    display: none;
  }
  .login__form {
    max-width: 100%;
  }
  .notifications__item {
    position: relative;
    border-radius: 0;
    border-bottom: 1px solid #E4E4E4;
  }
  .notifications__item:hover {
    background: none;
  }
  .notifications__head {
    margin-bottom: 24px;
  }
  .notifications__body {
    position: static;
  }
  .notifications .dropdown {
    top: 113px;
    right: auto;
    left: 20px;
    visibility: visible;
    opacity: 1;
  }
  .notifications .dropdown__body {
    left: -16px;
    right: auto;
  }
  body.dark .notifications__item {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .notifications__item:hover {
    background: none;
  }
  .activity__sorting {
    margin-bottom: 32px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .activity__select {
    margin: 0;
  }
  .activity__select .select {
    padding-left: 20px;
  }
  .activity__select .activity__icon {
    display: none;
  }
  .activity__row:not(.activity__row_head):hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .activity__table.small .activity__hidden {
    display: block;
  }
  .activity__table .activity__hidden {
    display: block;
  }
  .activity__table.small .activity__row_head .activity__hidden {
    display: inline;
  }
  .activity__table .activity__row_head .activity__hidden {
    display: inline;
  }
  .activity__cell {
    padding-right: 16px;
  }
  .activity__cell:first-child {
    padding-left: 16px;
  }
  .activity__cell:nth-child(3) {
    display: none;
  }
  .activity__table.small .activity__cell:nth-child(3) {
    display: none;
  }
  .popup_settings .mfp-close {
    right: 24px;
  }
  .balances__cell:nth-child(3), .balances__cell:nth-child(4) {
    display: none;
  }
  .balances__table.small .balances__cell:nth-child(4) {
    display: none;
  }
  .search__preview {
    left: 52%;
    width: 550px;
  }
  .search:not(:last-child) {
    margin-bottom: 80px;
  }
  .prices__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -64px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .prices__container::-webkit-scrollbar {
    display: none;
  }
  .prices__container:before, .prices__container:after {
    content: "";
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 64px;
    height: 1px;
  }
  .prices__table {
    width: 860px;
  }
  .prices__row:not(.prices__row_head):hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .exchange__cell:nth-child(4) {
    border: none;
  }
  .exchange__cell:nth-child(n+4) {
    display: none;
  }
  .exchange__sidebar {
    display: block;
    margin: 0;
  }
  .exchange .orders:not(:last-child) {
    margin-bottom: 56px;
  }
  .exchange .orders,
  .exchange .actions {
    width: 100%;
    margin: 0;
  }
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-text-right {
    text-align: right !important;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .select {     
    height: 56px;
    padding-left: 16px;
    padding-right: 36px;
    background: rgba(228, 228, 228, 0.3);
    border-radius: 12px;
    line-height: 56px;
  }
  .select:before {
    right: 16px;
  }
  .select .current {
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select .list {
    padding: 20px 16px;
  }
  .select .option {
    padding-left: 0;
  }
  .select .option:before {
    display: none;
  }
  .select_empty .list {
    left: 0;
    right: 0;
    width: auto;
    margin-top: 7px;
  }
  .select_empty.open {
    -webkit-box-shadow: inset 0 0 0 2px #355DFF;
    box-shadow: inset 0 0 0 2px #355DFF;
    background: #ffffff;
  }
  .select_arrows {
    padding-left: 20px;
  }
  body.dark .select {
    background: rgba(228, 228, 228, 0.03);
  }
  .more {
    margin-top: 24px;
  }
  .page__container_exchange {
    padding-bottom: 0;
  }
  .page__row {
    padding: 0 16px 32px;
  }
  .page__col:first-child {
    margin-bottom: 42px;
  }
  .page__head {
    margin-bottom: 24px;
  }
  .page__category {
    font-size: 18px;
  }
  .page__title {
    font-size: 32px;
  }
  .sidebar {
    padding: 94px 20px 72px;
  }
  .sidebar__head {
    height: 94px;
  }
  .sidebar__nav {
    margin-bottom: 16px;
  }
  .sidebar__item {
    height: 52px;
  }
  .sidebar__user {
    display: inline-block;
  }
  .header {
    padding: 24px 16px;
  }
  .header__logo img {
    width: 46px;
  }
  .header__item_notifications {
    position: static;
    margin-right: 24px;
  }
  .header__item_download {
    display: none;
  }
  .header__item_lang {
    display: none;
  }
  .header__item_notifications .header__body {
    top: 100%;
    left: 0;
    right: 0;
    width: auto;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    padding: 0 16px 24px;
    -webkit-box-shadow: 0 10px 30px rgba(17, 20, 45, 0.2);
    box-shadow: 0 10px 30px rgba(17, 20, 45, 0.2);
  }
  .header__notifications {
    margin-bottom: 12px;
  }
  .header__notification {
    padding: 12px 0;
  }
  .header__icon {
    margin-right: 16px;
  }
  .header__info {
    font-size: 14px;
  }
  .header__user {
    display: none;
  }
  .header__toggle {
    margin: 0;
  }
  .slider_home .slider__item {
    min-height: 591px;
  }
  .slider_home .slider__preview {
    left: -80%;
    bottom: -145px;
  }
  .slider_promotions .slider__item {
    min-height: 508px;
  }
  .slider_promotions .slider__preview {
    left: 15%;
    bottom: -27%;
    width: 600px;
  }
  .slider:not(:last-child) {
    margin-bottom: 32px;
  }
  .widgets__sorting {
    margin: 0 -8px 24px;
  }
  .widgets__sorting .select {
    margin: 0;
  }
  .widgets__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
  }
  .widgets__variants {
    display: none;
  }
  .widgets__list {
    display: block;
    margin: 0;
  }
  .widgets__item {
    max-width: 100%;
    margin: 0;
  }
  .widgets__item:not(:last-child) {
    margin-bottom: 32px;
  }
  .widgets__wrap {
    display: block;
    margin: 0;
  }
  .widgets__wrap .widgets__box {
    width: 100%;
    margin: 0;
  }
  .widgets__wrap .widgets__box:not(:last-child) {
    margin-bottom: 24px;
  }
  .card__chart_total-balance {
    margin-top: -15px;
  }
  .card__inner {
    text-align: center;
  }
  .card_widget {
    display: block;
  }
  .card_widget .card__chart {
    width: 100%;
    margin-right: 0;
  }
  .dropdown__body {
    top: calc(100% + 5px);
    right: 0;
  }
  .login__row {
    display: block;
  }
  .login__col:nth-child(2) {
    width: 100%;
  }
  .login__logo img {
    width: 40px;
  }
  .login__verify {
    margin-bottom: 24px;
  }
  .login__note {
    margin-bottom: 24px;
  }
  .notifications__sorting {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -8px 24px;
  }
  .notifications__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
  }
  .notifications__search {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 16px);
    flex: 0 0 calc(100% - 16px);
    margin: 16px 8px 0;
  }
  .notifications__input {
    padding-left: 24px;
  }
  .notifications__container {
    padding: 0 8px 32px;
  }
  .notifications__head {
    margin-bottom: 16px;
  }
  .notifications__time {
    margin-left: 15px;
  }
  .notifications__body {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .notifications__status {
    position: relative;
    top: 3px;
    margin-left: 15px;
  }
  .notifications .dropdown {
    top: 118px;
  }
  .activity__container {
    padding: 0 16px 32px;
  }
  .activity__sorting {
    margin: 0 -8px 24px;
  }
  .activity__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
  }
  .activity__stage {
    margin-bottom: 24px;
  }
  .activity__cell:first-child {
    display: none;
  }
  .activity__cell:nth-child(2) {
    border-radius: 12px 0 0 12px;
    padding-left: 16px;
  }
  .activity__cell:nth-child(4) {
    border-radius: 0 12px 12px 0;
  }
  .activity__cell:nth-child(n+5) {
    display: none;
  }
  .activity__logo {
    margin-right: 10px;
  }
  .activity__price, .activity__address {
    font-size: 13px;
  }
  .activity__sidebar {
    padding: 0;
  }
  .activity__wrap {
    min-height: 100vh;
    padding: 24px 16px;
    border-radius: 0;
  }
  .activity__close {
    top: 22px;
    right: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .promotions__wrapper {
    padding: 0 16px 32px;
  }
  .promotions__brand {
    font-size: 18px;
  }
  .promotions__title {
    margin-bottom: 24px;
    font-size: 32px;
  }
  .promotions__row {
    margin-bottom: 32px;
  }
  .promotions__stage {
    font-size: 18px;
  }
  .promotions__group_row {
    display: block;
    margin: 0;
  }
  .promotions__group_row:before, .promotions__group_row:after {
    display: none;
  }
  .promotions__box {
    padding: 24px;
  }
  .promotions__group_row .promotions__box {
    width: 100%;
  }
  .promotions__group_row .promotions__box:not(:last-child) {
    margin: 0 0 16px;
  }
  .promotions__list {
    display: block;
    margin: 0 0 32px;
  }
  .promotions__item {
    width: 100%;
    margin: 0;
  }
  .promotions__item:not(:last-child) {
    margin-bottom: 32px;
  }
  .promotions__transactions, .promotions__wrap {
    width: 100%;
    margin: 0;
  }
  .promotions__transactions:not(:last-child), .promotions__wrap:not(:last-child) {
    margin-bottom: 32px;
  }
  .promotions__cell:first-child {
    padding: 0 24px;
  }
  .promotions__cell:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    width: 90px;
  }
  .promotions__cell:nth-child(3) {
    padding: 0 24px;
  }
  .promotions_flex {
    display: block;
    margin: 0;
  }
  .promotion__body {
    padding: 24px 8px 16px;
  }
  .promotion__text:not(:last-child) {
    margin-bottom: 24px;
  }
  .promotion__note {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .promotion__icon {
    margin-top: 5px;
  }
  .promotion__btn {
    min-width: 100%;
  }
  .popup > .popup__title {
    margin-bottom: 32px;
  }
  .popup__nav {
    display: none;
  }
  .popup__user:not(:last-child) {
    padding-bottom: 24px;
  }
  .popup__ava {
    width: 70px;
    height: 70px;
    margin-right: 16px;
  }
  .popup__btns {
    margin-bottom: 5px;
  }
  .popup__btns .popup__btn {
    padding: 0 10px;
  }
  .popup__upload {
    min-width: auto;
    padding: 0 10px;
  }
  .popup__default {
    font-size: 12px;
  }
  .popup__row {
    display: block;
    margin: 0;
  }
  .popup__row .popup__field {
    width: 100%;
    margin: 0;
  }
  .popup__row .popup__field:not(:last-child) {
    margin-bottom: 20px;
  }
  .popup__item > .popup__btn {
    min-width: 100%;
  }
  .popup__form {
    margin: 20px 0 0;
  }
  .popup__title {
    margin-bottom: 24px;
  }
  .popup__box:not(:last-child) {
    margin-bottom: 32px;
  }
  .popup__scan {
    display: block;
  }
  .popup__scan .popup__preview {
    width: 140px;
    margin: 0 auto 16px;
  }
  .popup__scan .popup__details {
    padding: 0;
    text-align: center;
  }
  .popup__numbers {
    margin: 0 -5px;
  }
  .popup__number {
    margin: 0 5px;
  }
  .popup__number input {
    height: 44px;
    font-size: 20px;
    font-weight: 500;
  }
  .popup__table tr th:nth-child(3),
  .popup__table tr td:nth-child(3) {
    border-radius: 0 8px 8px 0;
  }
  .popup__table tr th:nth-child(n+4),
  .popup__table tr td:nth-child(n+4) {
    display: none;
  }
  .popup .mfp-close {
    width: 40px;
    height: 40px;
  }
  .popup_settings {
    min-height: 100vh;
    margin: 0;
    padding: 32px 24px;
    border-radius: 0;
  }
  .mfp-container {
    padding: 0;
  }
  .wallets__container {
    padding: 0 16px 32px;
  }
  .wallets__sidebar {
    padding: 0;
    border-radius: 0;
  }
  .wallets__wrapper {
    margin-bottom: 40px;
    padding: 24px;
  }
  .wallets__row {
    display: block;
  }
  .wallets__total {
    margin-bottom: 48px;
    padding: 0;
  }
  .wallets__group {
    width: 100%;
  }
  .wallets__inner {
    margin-top: 32px;
  }
  .wallets__list {
    display: block;
    margin: 0;
  }
  .wallets__item {
    width: 100%;
    margin: 0;
  }
  .wallets__item:not(:last-child) {
    margin-bottom: 24px;
  }
  .wallets__wrap {
    padding: 24px 16px;
    border-radius: 0;
  }
  .wallets__close {
    top: 22px;
    right: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .wallets__parameters {
    display: block;
    padding: 0;
    border-width: 1px 0 0;
  }
  .wallets__parameter {
    padding: 24px 0;
    border-bottom: 1px solid #E4E4E4;
  }
  .wallets__parameter:last-child {
    padding-bottom: 24px;
  }
  .balances__title {
    margin-bottom: 24px;
  }
  .balances__cell {
    padding-left: 16px;
  }
  .balances__cell:nth-child(n+3) {
    display: none;
  }
  .balances__cell:last-child {
    display: table-cell;
    padding-right: 16px;
  }
  .balances__table.small .balances__cell:nth-child(4),
  .balances__table.small .balances__cell:nth-child(5) {
    display: none;
  }
  .balances__btns {
    margin-top: 24px;
  }
  .search__container {
    height: 590px;
    overflow: hidden;
  }
  .search__form {
    max-width: 100%;
  }
  .search__input {
    padding-left: 20px;
  }
  .search__preview {
    top: auto;
    left: -18%;
    bottom: -22%;
    width: 600px;
  }
  .search:not(:last-child) {
    margin-bottom: 32px;
  }
  .categories__select .select {
    height: 64px;
    padding-left: 24px;
    padding-right: 64px;
    border-radius: 16px;
    line-height: 64px;
    font-size: 18px;
  }
  .categories__select .select:before {
    right: 24px;
  }
  .categories__container {
    display: none;
  }
  .categories:not(:last-child) {
    margin-bottom: 42px;
  }
  .prices__head {
    margin-bottom: 24px;
  }
  .prices__head .select {
    min-width: 164px;
    margin-left: 20px;
  }
  .prices__title {
    font-size: 18px;
    font-weight: 500;
  }
  .prices__container {
    margin: 0 -16px;
  }
  .prices__container:before, .prices__container:after {
    width: 16px;
  }
  .prices__btns {
    margin-top: 16px;
  }
  .details__container {
    padding: 24px;
  }
  .details__top {
    display: block;
  }
  .details__company {
    width: 100%;
    margin: 0 0 32px;
  }
  .details__btns {
    margin: 0 -8px;
  }
  .details__btns .details__btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    min-width: calc(50% - 16px);
    margin: 0 8px;
    padding: 0 10px;
  }
  .details__btns .details__btn:not(:last-child) {
    margin-right: 8px;
  }
  .details__flex {
    display: block;
  }
  .details__status {
    margin: 12px 0 0;
  }
  .details__chart {
    margin: -32px -44px 5px -28px;
  }
  .details__chart .apexcharts-text {
    font-size: 10px;
  }
  .details__list {
    display: block;
  }
  .details__box {
    width: 100%;
    height: 112px;
    padding: 16px 32px;
  }
  .details__box:nth-child(2n+1) {
    border-right: none;
  }
  .details__price {
    font-size: 18px;
  }
  .details__row {
    display: block;
  }
  .details__col:first-child {
    max-width: 100%;
    margin: 0 0 32px;
  }
  .exchange {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: calc(100vh - 94px);
  }
  .exchange__top {
    margin-bottom: 24px;
  }
  .exchange__cell:nth-child(n+3) {
    display: none;
  }
  .exchange__container {
    margin: 0;
  }
  .exchange .orders:not(:last-child) {
    margin: 0;
  }
  .exchange .orders,
  .exchange .actions {
    display: none;
  }
  .exchange__foot {
    display: block;
  }
  .market {
    border: none;
    border-radius: 0;
  }
/*  body.dark .market {
  background-color: #242731;
  }*/
  .market__tabs {
    border: 1px solid #E4E4E4;
    /*background: #242731;*/
    border-radius: 32px;
  }
  .market__head {
    display: block;
    padding-bottom: 16px;
  }
  .market__menu {
    margin: 0 -5px 16px;
  }
  .market__menu .market__link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 0 5px;
    text-align: center;
  }
  .market__action:last-child {
    margin-left: auto;
  }
  .market__chart_price {
    margin-top: 0;
    margin-left: -22px;
    margin-right: -12px;
  }
  .market__transactions {
    display: none;
  }
  .market__top {
    margin-bottom: 16px;
    padding: 0;
  }
  .market__title {
    margin-bottom: 16px;
  }
  .market__table {
    border: 1px solid #E4E4E4;
    border-radius: 20px;
  }
  .market__row:first-child .market__cell {
    border-top: none;
  }
  .market__cell {
    padding-left: 18px;
  }
  .market__cell:last-child {
    padding-right: 18px;
  }
  body.dark .market__table {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .actions__fieldset {
    margin-bottom: 24px;
  }
  body.dark .actions .select {
    height: 64px;
  }
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-text-right {
    text-align: right !important;
  }
}

/*# sourceMappingURL=app.css.map */

.upload__item:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (min-width: 1260px) {
  .upload__item {
    display: block !important;
  }
}

@media only screen and (max-width: 1259px) {
  .upload__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .upload__item:nth-child(2) {
    display: none;
  }
  .upload__item:not(:last-child) {
    margin: 0;
  }
  .upload__stat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .upload__cell:nth-child(2) {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-bottom: 8px;
  }
  .upload__cell:not(:last-child) {
    margin: 0 40px 0 0;
  }
  .upload_solo .upload__row {
    display: block;
  }
  .upload_solo .upload__col:nth-child(2) {
    width: 100%;
    margin: 40px 0 0;
  }
  .upload_solo .upload__wrap {
    height: 420px;
  }
}

@media only screen and (max-width: 767px) {
  .upload__item {
    display: block !important;
  }
  .upload__item:nth-child(2) {
    display: block;
  }
  .upload__item:not(:last-child) {
    margin-bottom: 16px;
  }
  .upload__col:nth-child(2) {
    width: 100%;
    margin: 0;
  }
  .upload__nav {
    display: none;
  }
  .upload__container {
    margin: 0 -8px;
  }
  .upload__item {
    display: block !important;
  }
  .upload__item:nth-child(2) {
    display: block;
  }
  .upload__item:not(:last-child) {
    margin-bottom: 16px;
  }
  .upload__wrap {
    padding: 32px;
  }
  .upload__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -8px 60px;
  }
  .upload__file {
    display: block;
  }
  .upload__btns > .upload__btn {
    -webkit-box-flex: calc(50% - 16px);
    -ms-flex: calc(50% - 16px);
    flex: calc(50% - 16px);
    min-width: calc(50% - 16px);
    margin: 0 8px;
  }
  .upload__stat {
    display: block;
  }
  .upload__cell:not(:last-child) {
    margin: 0 0 54px;
  }
  .upload:not(:last-child) {
    margin-bottom: 32px;
  }
  .upload_empty .upload__row {
    display: block;
  }
  .upload_empty .upload__col:first-child {
    display: none;
  }
  .upload_empty .upload__col:nth-child(2) > .upload__btn {
    min-width: calc(50% - 16px);
    margin: 0 8px;
  }
  .upload_empty .upload__head {
    display: none;
  }
  .upload_solo {
    margin: -16px 0 0;
  }
  .upload_solo .upload__wrap {
    height: 343px;
  }
}

.upload__wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 52px;
  overflow: hidden;
  background-color: rgba(228, 228, 228, 0.5);
  border: 2px dashed rgba(228, 228, 228, 0.5);
  border-radius: 16px;
  text-align: center;
}

.upload__wrap_image {
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: #ffffff;
}

.upload__wrap_image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(31%, rgba(0, 0, 0, 0.36)), color-stop(82%, rgba(24, 26, 28, 0.9)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.36) 31%, rgba(24, 26, 28, 0.9) 82%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.36) 31%, rgba(24, 26, 28, 0.9) 82%);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.36) 31%, rgba(24, 26, 28, 0.9) 82%);
}

.upload__btns {
  margin-bottom: 54px;
}

.upload__file {
  display: none;
  position: relative;
  -webkit-box-flex: calc(50% - 16px);
  -ms-flex: calc(50% - 16px);
  flex: calc(50% - 16px);
  min-width: calc(50% - 16px);
  margin: 0 8px;
  overflow: hidden;
}

.upload__file .upload__btn {
  min-width: 100%;
}

.upload__wrap .upload__input,
.upload__file .upload__input {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 250px;
  opacity: 0;
}

.upload__box {
  position: relative;
  z-index: 2;
  text-align: center;
}

.upload__info {
  margin-bottom: 24px;
}

.upload__wrap:not(.upload__wrap_image):hover .upload__btn {
  background: #1b77ff;
}

.upload__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}

.upload__wrap .upload__btn {
  min-width: auto;
}

.upload__head .upload__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  font-size: 0;
  -webkit-transition: all .25s;
  -o-transition: all .25s;
  transition: all .25s;
}

.upload__head .upload__link .icon {
  font-size: 16px;
  fill: #11142D;
  -webkit-transition: fill .25s;
  -o-transition: fill .25s;
  transition: fill .25s;
}

.upload__head .upload__link:hover {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.upload__head .upload__link:hover .icon {
  fill: #6C5DD3;
}

.upload__col:nth-child(2) > .upload__btn {
  min-width: 122px;
}

.upload__cell:not(:last-child) {
  margin-bottom: 54px;
}

.upload__line {
  position: relative;
  margin-bottom: 20px;
  height: 4px;
  border-radius: 2px;
  background: #E4E4E4;
}

.upload__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #3F8CFF;
  border-radius: 2px;
}

.upload__tooltip {
  position: absolute;
  right: -28px;
  bottom: calc(100% + 10px);
  width: 56px;
  border-radius: 6px;
  background: #1B1D21;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.upload__tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #1B1D21 transparent transparent transparent;
}

.upload .widget:not(:first-child) {
  margin-top: 40px;
}

.upload:not(:last-child) {
  margin-bottom: 64px;
}

.upload_solo .upload__wrap {
  height: 450px;
}

.upload_solo .upload__info {
  margin-bottom: 8px;
}

.upload_solo .upload__wrap .upload__text {
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 600;
}

.upload_solo .upload__wrap:not(.upload__wrap_image) .upload__text {
  color: #808191;
}

body.dark .upload__wrap {
  background-color: rgba(228, 228, 228, 0.1);
  border-color: rgba(240, 243, 246, 0.1);
}

body.dark .upload__head .upload__link {
  background: #242731;
}

body.dark .upload__head .upload__link .icon {
  fill: #ffffff;
}

body.dark .upload__head .upload__link:hover .icon {
  fill: #6C5DD3;
}

body.dark .upload__line {
  background: rgba(240, 243, 246, 0.1);
}




