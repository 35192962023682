.reactour__helper {
  width: 351px;
  max-width: 351px;
  min-width: 351px;
}

[data-tour-elem='badge'] {
  border: 3px solid #F4F4F7;
  box-shadow: none !important;
  line-height: 1.6 !important;
  font-family: var(--font-default) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
}

[data-tour-elem$='arrow'] {
  width: 28px !important;
  height: 28px !important;
  flex: 0 28px !important;
  padding: 8px !important;
}

[data-tour-elem='right-arrow'] {
  border-radius: 50% !important;
  background-color: var(--color-primary) !important;
  color: #FFF !important;
}

[data-tour-elem$='arrow']:disabled {
  visibility: hidden;
}
/* 
[data-tour-elem='controls'] {
  justify-content: space-between !important;
} */

