.gridjs-wrapper {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

tr.gridjs-tr:nth-child(even),
thead.gridjs-thead > tr.gridjs-tr {
  background-color: rgba(228, 228, 228, 0.2);
  color: rgb(17, 20, 45);
  transition: all 0.2s ease 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

tr.gridjs-tr {
  border-radius: 12px;
  box-shadow: none;
  overflow: hidden;
}

tbody.gridjs-tbody tr.gridjs-tr:hover {
  background: none;
  box-shadow: rgb(0 0 0 / 7%) 0px 3px 10px;
}

td.gridjs-td,
th.gridjs-th {
  background-color: transparent;
  border: none;
}

th.gridjs-th {
  vertical-align: middle;
  font-family: var(--font-secondary);
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-style: normal;
  color: rgb(128, 129, 145);
  line-height: 15px;
}

td.gridjs-td {
  display: table-cell;
  vertical-align: middle;
  height: 72px;
  font-weight: 600;
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 24px;
}

td.gridjs-td:nth-child(3) {
  font-weight: 600;
  color: rgb(128, 129, 145);
}

td.gridjs-td:nth-child(4) {
  color: rgb(255, 122, 104);
}

td.gridjs-td:nth-child(5) {
  text-align: center;
}

button.gridjs-sort {
  width: 10px;
  height: 10px;
}

div.gridjs-footer {
  padding: 12px 24px;
  border-top: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.gridjs-td.gridjs-message.gridjs-notfound {
  height: 400px;
  width: 100%;
  background-image: url('../assets/empty-table.svg');
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
}